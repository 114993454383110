import { Search } from '@mui/icons-material'
import { Autocomplete, TextField, Tooltip } from '@mui/material'
import { useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Map, MapRef, Marker, useMap } from 'react-map-gl'
import { Link } from 'react-router-dom'
import uuid from 'react-uuid'
import WebMercatorViewport from 'viewport-mercator-project'
import anchor from '~/assets/images/anchor_red.png'
import { ISitesMapProps } from '~/services/Dashboard/types'
import { defaultZoomSpeed } from '~/utils'
import { validarLatitude, validarLongitude } from '~/utils/Validator'
import * as S from '../../styles'

type DashboardMapProps = {
  sitesMap: ISitesMapProps[]
}

const defaultLocation = {
  latitude: 0,
  longitude: 0,
  zoom: 1,
}

const applyToArray = (func: () => number, values: any) => func.apply(Math, values)

const DashboardMap = ({ sitesMap }: DashboardMapProps) => {
  const { t } = useTranslation()

  const mapRef = useRef<MapRef>(null)
  const [mapData, setMapData] = useState(defaultLocation)
  const [selectedSite, setSelectedSite] = useState<ISitesMapProps | null>(null)

  const getBoundsForPoints = () => {
    const pointsLong = sitesMap
      ?.filter((point) => validarLongitude(point.longitude.toString()))
      ?.map((point) => point.longitude)
    const pointsLat = sitesMap
      ?.filter((point) => validarLatitude(point.latitude.toString()))
      ?.map((point) => point.latitude)

    if (!pointsLong?.length || !pointsLat?.length) return
    const cornersLongLat: [[number, number], [number, number]] = [
      [applyToArray(Math.min, pointsLong), applyToArray(Math.min, pointsLat)],
      [applyToArray(Math.max, pointsLong), applyToArray(Math.max, pointsLat)],
    ]

    if (cornersLongLat) {
      const viewport = new WebMercatorViewport({ width: 500, height: 500 }).fitBounds(cornersLongLat, {
        padding: 50,
      })
      const { longitude, latitude, zoom } = viewport
      return { longitude, latitude, zoom }
    }
  }

  const defaultViewport = useMemo(() => {
    const viewport = getBoundsForPoints()

    if (viewport) {
      setSelectedSite(null)
      setMapData(viewport)
      return viewport
    } else return defaultLocation
  }, [sitesMap])

  const handleChangeValue = (e: any, value: string | null) => {
    const newSelectedSite = sitesMap.filter((site) => site.name === value)[0]
    setSelectedSite(newSelectedSite)

    if (newSelectedSite) {
      dynamicMap?.flyTo({
        center: [newSelectedSite.longitude, newSelectedSite.latitude],
        zoom: 16,
        speed: defaultZoomSpeed,
      })
    } else
      dynamicMap?.flyTo({
        center: [defaultViewport.longitude, defaultViewport.latitude],
        zoom: defaultViewport.zoom,
        speed: defaultZoomSpeed,
      })
  }

  const filteredMaps = useMemo(() => {
    return (
      sitesMap.filter(
        (anomaly) =>
          validarLatitude(anomaly.latitude.toString()) && validarLatitude(anomaly.longitude.toString()),
      ) ?? []
    )
  }, [sitesMap])

  const mapId = useMemo(() => {
    return uuid()
  }, [])

  const { [mapId]: dynamicMap } = useMap()

  return (
    <S.MapWrapper>
      <S.Header>
        <h4>{t('Mapa dos parques solares')}</h4>
        <S.Chip
          label="Reset location"
          color="primary"
          onClick={() =>
            dynamicMap?.flyTo({
              center: [defaultViewport.longitude, defaultViewport.latitude],
              zoom: defaultViewport.zoom,
              speed: defaultZoomSpeed,
            })
          }
        />
      </S.Header>
      <S.SiteInputWrapper>
        <Autocomplete
          disablePortal
          id="combo-box-demo"
          fullWidth
          size="small"
          value={selectedSite?.name ?? ''}
          onChange={handleChangeValue}
          options={filteredMaps?.map((site) => site.name) ?? []}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              fullWidth
              placeholder={t('Procurar parque solar') ?? ''}
              value={selectedSite?.name ?? ''}
              InputProps={{
                ...params?.InputProps,
                startAdornment: <Search />,
              }}
            />
          )}
        />
      </S.SiteInputWrapper>

      <S.MapContent>
        <Map
          id={mapId}
          reuseMaps
          {...mapData}
          ref={mapRef}
          mapStyle="mapbox://styles/mapbox/satellite-v9"
          onMove={(evt) => {
            setMapData(evt.viewState)
          }}
          mapboxAccessToken="pk.eyJ1IjoidGh5ZXJyeSIsImEiOiJjbGU3aWUxeW4wNW93M3FxZ250Yjg3ODg1In0.C1hUGICSXdbIAYWI47L4Bg"
        >
          {filteredMaps.map((anomaly) => (
            <Marker
              key={anomaly.id}
              latitude={validarLatitude(anomaly.latitude.toString()) ? anomaly.latitude : 0}
              longitude={validarLatitude(anomaly.longitude.toString()) ? anomaly.longitude : 0}
              offset={[0, -20]}
              style={{ zIndex: 99 }}
            >
              <Link to={`/sites/inspections/${anomaly.id}`}>
                <Tooltip title={anomaly.name} placement={anomaly.latitude >= 0 ? 'bottom' : 'top'}>
                  <div style={{ cursor: 'pointer' }}>
                    <img
                      style={{ height: 50, width: 50, zIndex: 1 }}
                      src={anchor}
                      alt="Anomaly Location"
                    />
                  </div>
                </Tooltip>
              </Link>
            </Marker>
          ))}
        </Map>
      </S.MapContent>
    </S.MapWrapper>
  )
}

export default DashboardMap
