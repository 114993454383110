import MapIcon from '@mui/icons-material/Map'
import { Box, Button } from '@mui/material'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import ToastNotification from '~/components/ToastNotification'
import BaseLayout from '~/Layouts/BaseLayout'
import { getDashboardInspectionService } from '~/services/Dashboard'
import { IDashboardInspectionProps, IInspectionInfosProps } from '~/services/Dashboard/types'
import * as S from '../../styles'
import { defaultInspectionData } from '../../utils'
import AllAnomaliesEffectComponent from '../AllAnomaliesEffect'
import AnomalieSeverityComponent from '../AnomalieSeverity'
import PieChartComponent from '../AnomaliesPieChart'
import BenchmarkComponent from '../Benchmark'
import ModulePieChartComponent from '../ModulesPieChart'
import SiteReport from '../SiteReport'
import SummaryComponent from '../Summary'

const InspectionSite = () => {
  const { t } = useTranslation()
  const { siteId } = useParams()
  const navigate = useNavigate()

  const [inspectionData, setInspectionData] = useState<IDashboardInspectionProps>(defaultInspectionData)
  const getInspectionData = async (siteId: string) => {
    try {
      const resp = await getDashboardInspectionService(siteId)
      if (resp.success) {
        setInspectionData(resp.data)
      } else {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Não foi possível carregar os dados, tente novamente mais tarde.'),
          errorMessage: resp.errorDetails,
          errors: resp.errors,
        })
      }
    } catch (e) {
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Não foi possível carregar os dados, tente novamente mais tarde.'),
      })
      console.error('error updating', e)
    }
  }

  useEffect(() => {
    if (siteId) getInspectionData(siteId)
  }, [])

  const handleMapClick = () => {
    navigate(`/mapa`, {
      state: {
        siteId: inspectionData.infos.siteId,
        inspectionId: siteId,
      },
    })
  }

  return (
    <BaseLayout
      extraContent={
        <Box sx={{ display: 'flex', width: '100%', justifyContent: 'end', marginRight: '15px' }}>
          <Button
            startIcon={<MapIcon sx={{ color: 'white' }} />}
            sx={{ display: 'flex', width: '250px' }}
            variant="contained"
            color="primary"
            onClick={handleMapClick}
          >
            {t('Mapa')}
          </Button>
        </Box>
      }
      title={inspectionData?.infos ? <PageHeader {...inspectionData?.infos} /> : ''}
    >
      <S.ContentWrapper>
        <S.HeaderContent>
          <SummaryComponent summary={inspectionData?.summary} dataType="inspection" />
          <AnomalieSeverityComponent severityGraph={inspectionData.severityGraph} />
        </S.HeaderContent>
        <S.OneSize>
          <S.DescriptionWrapper>
            <h4>{t('Informação Adicional')}</h4>
            <S.DescriptionContent>
              <p>{inspectionData.infos.inspectionDescription}</p>
            </S.DescriptionContent>
          </S.DescriptionWrapper>
        </S.OneSize>
        <SiteReport
          anomalyTypesImpactList={inspectionData.anomalyTypesImpactList}
          currencySymbol={inspectionData.infos.currencySymbol}
        />
        <S.EqualSizes>
          <PieChartComponent anomalyTypeBars={inspectionData?.anomalyTypeBars} />
          <ModulePieChartComponent
            totalModules={inspectionData?.summary?.inspectedNumberOfModules ?? 0}
            totalAnomalies={inspectionData?.severityGraph?.totalAnomalies}
          />
        </S.EqualSizes>
        <AllAnomaliesEffectComponent anomalyTypeBars={inspectionData.anomalyTypeBars} />

        <BenchmarkComponent globalBenchmark={inspectionData.comparativeBenchmark} />
      </S.ContentWrapper>
    </BaseLayout>
  )
}

export default InspectionSite

const PageHeader = (props?: IInspectionInfosProps) => {
  const { t } = useTranslation()

  return (
    <S.HeaderInspection>
      <h2>{props?.siteName}</h2>
      <p>
        {t('Data da inspeção')}: {moment(props?.inspectionDate).format('DD/MM/YYYY')}
      </p>
    </S.HeaderInspection>
  )
}
