import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TableCell,
  TablePagination,
  TextField,
} from '@mui/material'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { PAGE_SIZE } from '~/assets/Constants'
import InputSearch from '~/components/InputSearch'
import ToastNotification from '~/components/ToastNotification'
import ViewError from '~/components/ViewError'
import ViewLoading from '~/components/ViewLoading'
import { IReportType } from './types'

import Button from '~/components/Button'
import * as R from '../Sites/UploadImages/styles'
import * as G from '../styles'
import * as S from './styles'

import { Alarm, BugReport, Thermostat, WorkOutline } from '@mui/icons-material'
import { MaterialIcon } from '~/assets/icons/materialIcons'
import MaterialTable from '~/components/Material/Table'
import { useAuth } from '~/contexts/Auth'
import BaseLayout from '~/Layouts/BaseLayout'
import { getReports, postReports } from '~/services/Reports'
import { IReportHistoryArray, IReportHistoryDto, ReportTypeDescriptions } from '~/services/Reports/types'
import { DataMaskFormtData, validateIfNull } from '~/utils/Mask'
import ReportModal from './ReportModal'
import { AccessLevel } from '~/utils/General'
import ConfirmationModal from './ConfirmationModal'

export type ReportOptionType = {
  value: IReportType
  label: string
}

export const reportTypeOptions = Object.keys(IReportType)
  .filter((key) => !isNaN(Number(IReportType[key as keyof typeof IReportType])))
  .map((key) => ({
    value: IReportType[key as keyof typeof IReportType],
    label: key,
  }))

export default function Reports() {
  const { t } = useTranslation()
  const { user } = useAuth()
  const endRef = useRef<HTMLDivElement>()

  const [loading, setLoading] = useState(false)
  const [list, setList] = useState<IReportHistoryArray>([])
  const [currentPage, setCurrentPage] = useState(1)

  const [reportName, setReportName] = useState<string>('')
  const [reportUserName, setReportUserName] = useState<string>('')
  const [fromDate, setFromDate] = useState('')
  const [toDate, setToDate] = useState('')
  const [formattedFromDate, setFormattedFromDate] = useState('')
  const [formattedToDate, setFormattedToDate] = useState('')
  const [totalCount, setTotalCount] = useState<number>(0)
  const bottomRef = useRef<HTMLDivElement>(null)

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [reportIdToDelete, setReportIdToDelete] = useState<string | null>(null)
  const [elementId, setElementId] = useState<number>()

  const handleCloseModal = () => {
    setIsModalOpen(false)
  }

  const [selectedReportType, setSelectedReportType] = useState<IReportType | undefined>(undefined)

  const handleChange = (event: SelectChangeEvent<IReportType>) => {
    setSelectedReportType(event.target.value as IReportType)
  }

  const handleFromDateReportsChangee = (e: any) => {
    const inputDateReports = e.target.value
    setFromDate(inputDateReports)

    if (inputDateReports) {
      const [year, month, day] = inputDateReports.split('-').map((num: any) => parseInt(num, 10))
      let date = new Date(Date.UTC(year, month - 1, day))
      const formattedDateTime = date.toISOString()
      setFormattedFromDate(formattedDateTime)
    }
  }

  const handleToDateReportsChange = (e: any) => {
    const inputDateReports = e.target.value
    setToDate(inputDateReports)
    if (inputDateReports) {
      const [year, month, day] = inputDateReports.split('-').map((num: any) => parseInt(num, 10))
      let date = new Date(Date.UTC(year, month - 1, day))
      const formattedDateTime = date.toISOString()
      setFormattedToDate(formattedDateTime)
    }
  }
  useEffect(() => {
    loadReport(1, true)
  }, [])

  const refreshReports = () => {
    loadReport(1, true)
  }

  const onEnterDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      loadReport(1)
    }
  }

  const scrollToBottom = () => {
    bottomRef.current?.scrollIntoView({ behavior: 'smooth' })
  }

  const loadReport = async (page: number = 1, clear: boolean = false, shouldScroll: boolean = false) => {
    setLoading(true)
    try {
      if (clear) {
        setList([])
      }
      const response = await getReports({
        ReportType: selectedReportType,
        page,
        pageSize: PAGE_SIZE,
        ReportName: reportName,
        Username: reportUserName,
        DateSince: formattedToDate,
        DateUntil: formattedFromDate,
      })
      if (response.success) {
        setCurrentPage(page)
        setList(response.data)
        response?.itemsTotal && setTotalCount(response?.itemsTotal)

        if (shouldScroll) {
          setTimeout(scrollToBottom, 0)
        }
      } else {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Não foi possível carregar os dados, tente novamente mais tarde.'),
          errorMessage: response.errorDetails,
          errors: response.errors,
        })
      }
    } catch (error) {
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Não foi possível carregar os dados, tente novamente mais tarde.'),
      })
    }
    setLoading(false)
  }

  const colunasRelatorios = [
    {
      label: t('Tipo'),
    },
    {
      label: t('Nome'),
    },
    {
      label: t('Parque solar'),
    },
    {
      label: t('Criado por'),
    },
    {
      label: t('Data de criação'),
    },
    {
      label: t('Data de Inspeção'),
    },
    {
      label: t('Status'),
    },
    {
      label: t('Ações'),
    },
  ]

  const createReports = async (item: IReportHistoryDto) => {
    setLoading(true)
    try {
      const body = {
        reportId: item.id,
      }

      const response = await postReports(body)

      if (response.success) {
        const url = response.data.reportUrl

        window.open(url, '_blank')
        ToastNotification({
          id: 'success',
          type: 'success',
          message: t('Arquivo gerado com sucesso!!'),
        })
      } else {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Erro ao gerar arquivo.'),
        })
      }
    } catch (error) {
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Ocorreu um erro ao gerar o arquivo.'),
      })
    }
    setLoading(false)
  }

  return (
    <BaseLayout title={t('Relatórios')}>
      {user?.accessLevel !== 0 && (
        <S.ContainerGrid>
          <S.TitleReports>{t('Gerar Relatórios')}</S.TitleReports>
          <S.GridReports>
            <button
              className="container anomalyModalButton"
              onClick={() => {
                setElementId(2)
                setIsModalOpen(true)
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  setElementId(2)
                  setIsModalOpen(true)
                }
              }}
              aria-label="Abrir modal de anomalias"
            >
              <WorkOutline fontSize="large" htmlColor="green" />
              <div>
                <h3>{t('Reports.ExecutiveReport')}</h3>
                <p>{t('Inclui dados de alto nível de inspeção do local')}</p>
              </div>
            </button>

            <button
              className="container anomalyModalButton"
              onClick={() => {
                setElementId(1)
                setIsModalOpen(true)
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  setElementId(1)
                  setIsModalOpen(true)
                }
              }}
              aria-label="Abrir modal de anomalias"
            >
              <Thermostat fontSize="large" htmlColor="orange" />
              <div>
                <h3>{t('Reports.ThermalReport')}</h3>
                <p>{t('Relatório térmico detalhado para todas as anomalias')}</p>
              </div>
            </button>

            <button
              className="container anomalyModalButton"
              onClick={() => {
                setElementId(3)
                setIsModalOpen(true)
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  setElementId(3)
                  setIsModalOpen(true)
                }
              }}
              aria-label="Abrir modal de anomalias"
            >
              <BugReport fontSize="large" htmlColor="red" />
              <div>
                <h3>{t('Reports.CSVAnomalies')}</h3>
                <p>
                  {t(
                    'Lista separada por ponto e vírgula de todas as anomalias com coordenadas de módulo',
                  )}
                </p>
              </div>
            </button>

            <button
              className="container anomalyModalButton"
              onClick={() => {
                setElementId(4)
                setIsModalOpen(true)
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  setElementId(4)
                  setIsModalOpen(true)
                }
              }}
              aria-label="Abrir modal de atividades"
            >
              <Alarm fontSize="large" htmlColor="blue" />
              <div>
                <h3>{t('Reports.CSVActivities')}</h3>
                <p>
                  {t(
                    'Lista separada por ponto e vírgula de todas as atividades vinculadas a um determinado parque solar',
                  )}
                </p>
              </div>
            </button>
          </S.GridReports>
        </S.ContainerGrid>
      )}

      <S.MainWrapper ref={endRef}>
        <S.ContainerFilter>
          <S.ContainerSearch>
            <InputSearch
              placeholder={t('Pesquise pelo nome do relatório') ?? 'Pesquise pelo nome do relatório'}
              value={reportName}
              onChange={(e) => {
                setReportName(String(e.target.value))
              }}
              onKeyDown={onEnterDown}
              variant="outlined"
              fullWidth
            />
            <InputSearch
              placeholder={t('Pesquise pelo nome do usuário') ?? 'Pesquise pelo nome do usuário'}
              value={reportUserName}
              onChange={(e) => {
                setReportUserName(String(e.target.value))
              }}
              onKeyDown={onEnterDown}
              variant="outlined"
              fullWidth
            />
          </S.ContainerSearch>

          <S.SelectsSearch>
            <S.SelectSearchInputs>
              <FormControl sx={{ width: '200px' }}>
                <Select
                  size="small"
                  labelId="select-label"
                  id="report-type-select"
                  value={selectedReportType}
                  label={t('Tipo de Relatório')}
                  onChange={handleChange}
                  sx={{
                    backgroundColor: 'white',
                  }}
                >
                  {Object.keys(IReportType)
                    .filter((key) => isNaN(Number(key)))
                    .map((key) => (
                      <MenuItem key={key} value={IReportType[key as keyof typeof IReportType]}>
                        {t(`ReportType.${key}`)}s
                      </MenuItem>
                    ))}
                </Select>
                <InputLabel id="select-label" size="small">
                  {t('Tipo de Relatório')}
                </InputLabel>
              </FormControl>
              <R.InputWrapper width="200px">
                <TextField
                  size="small"
                  type="date"
                  value={toDate || new Date()}
                  onChange={handleToDateReportsChange}
                  label={t('Data de Inspeção a partir de:') ?? 'Data de Inspeção a partir de:'}
                  fullWidth
                />
              </R.InputWrapper>

              <R.InputWrapper width="200px">
                <TextField
                  size="small"
                  type="date"
                  value={fromDate || new Date()}
                  onChange={handleFromDateReportsChangee}
                  label={t('Data de Inspeção até:') ?? 'Data de Inspeção até:'}
                  fullWidth
                />
              </R.InputWrapper>
            </S.SelectSearchInputs>

            <Button
              type="button"
              onClick={() => loadReport(1)}
              label={t('Pesquisar')}
              style={{ marginTop: '0', width: '250px' }}
            >
              {t('Pesquisar')}
            </Button>
          </S.SelectsSearch>
        </S.ContainerFilter>

        {loading ? (
          <ViewLoading />
        ) : (
          <G.Main>
            {list.length > 0 ? (
              <MaterialTable<IReportHistoryDto>
                headCells={colunasRelatorios}
                page={0}
                tableValues={list.map((item) => ({
                  id: item.id,
                  reportType: item?.reportType,
                  reportName: item?.reportName,
                  siteName: item?.siteName ?? 'Nome não definido',
                  userName: item?.userName,
                  reportDate: item?.reportDate,
                  inspectionDate: item?.inspectionDate,
                  completed: item.completed,
                }))}
                renderRow={(item) => {
                  return (
                    <>
                      <TableCell component="th" scope="row">
                        {t(ReportTypeDescriptions[item.reportType])}
                      </TableCell>
                      <TableCell align="right">{item.reportName}</TableCell>
                      <TableCell align="right">{validateIfNull(item.siteName)}</TableCell>
                      <TableCell align="right">{validateIfNull(item.userName)}</TableCell>
                      <TableCell align="right">{DataMaskFormtData(item.reportDate)}</TableCell>
                      <TableCell align="right">{DataMaskFormtData(item.inspectionDate)}</TableCell>
                      <TableCell align="right">
                        {item.completed ? t('Concluído') : t('Processando')}
                      </TableCell>
                      <TableCell align="right">
                        <S.Icons>
                          <button onClick={() => createReports(item)} disabled={!item.completed}>
                            <MaterialIcon
                              style={{
                                color: item.completed ? '#1976d2' : '#c4c4c4',
                              }}
                              name="Description"
                              title={item.completed ? t('Download PDF') : t('Aguarde Processar')}
                            />
                          </button>
                          {(user?.accessLevel === AccessLevel.Master ||
                            user?.accessLevel === AccessLevel.Admin) && (
                            <button onClick={() => setReportIdToDelete(item.id)}>
                              <MaterialIcon
                                style={{
                                  color: '#FF0000',
                                }}
                                name="Delete"
                                title={t('Excluir')}
                              />
                            </button>
                          )}
                        </S.Icons>
                      </TableCell>
                    </>
                  )
                }}
                pagination={() => (
                  <TablePagination
                    rowsPerPageOptions={[8]}
                    rowsPerPage={8}
                    component="div"
                    count={totalCount ?? 0}
                    page={currentPage - 1}
                    onPageChange={(e, p: any) => {
                      e?.preventDefault()
                      e?.stopPropagation()
                      loadReport(p + 1, false, true)
                    }}
                  />
                )}
              />
            ) : (
              <ViewError message={t('Nenhum relatório encontrado')} />
            )}
            <div ref={bottomRef} />
          </G.Main>
        )}
      </S.MainWrapper>
      <ReportModal
        reportId={elementId}
        onSuccess={refreshReports}
        open={isModalOpen}
        handleClose={handleCloseModal}
      />
      <ConfirmationModal
        reportId={reportIdToDelete}
        handleClose={() => setReportIdToDelete(null)}
        refreshReports={refreshReports}
      />
    </BaseLayout>
  )
}
