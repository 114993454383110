import InputMaterial from '~/components/Material/Input'
import ToastNotification from '~/components/ToastNotification'
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Tooltip,
  Autocomplete,
  TextField,
  Typography,
  Stack,
} from '@mui/material'
import { useEffect, useState } from 'react'
import { Controller, ControllerRenderProps, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { getSettings, patchSettings } from '~/services/Settings'
import { IInputOptionsProps, InputSystemProps, ValueToSend } from '~/services/Settings/types'
import InfoIcon from '@mui/icons-material/Info'
import * as S from '../styles'
import { SelectOption } from '~/pages/Dashboard/Reports/types'
import { getSimpleSite } from '~/services/Site'
import ErrorToast from '~/utils/toastErrorCatch'
import { getSiteValues, postSiteValues } from '~/services/SiteValues'
import { IRequestPostSiteValues, IResponseSiteValues } from '~/services/SiteValues/types'
import LoadingButton from '~/components/LoadingButton'
import { useAuth } from '~/contexts/Auth'

type GenericData = { [x: string]: string }

const Geral = () => {
  const { t } = useTranslation()

  const [loading, setLoading] = useState<boolean>(false)
  const [loadingSitio, setLoadingSitio] = useState<boolean>(false)
  const [inputSettings, setInputSettings] = useState<InputSystemProps[]>([])
  const [siteOptions, setSiteOptions] = useState<SelectOption[]>([])
  const [selectedSiteId, setSelectedSiteId] = useState('')
  const [respSiteValues, setRespSiteValues] = useState<IResponseSiteValues | null>(null)
  const { user } = useAuth()

  const {
    control,
    reset,
    watch,
    handleSubmit,
    formState: { isDirty },
  } = useForm<GenericData>()

  const watchFields = watch(['currency', 'sunlight-hours-year', 'cost-kwh'])

  const [canSaveGlobalSettings, setCanSaveGlobalSettings] = useState<boolean>(false)

  useEffect(() => {
    const hasChanges = watchFields.some((fieldValue) => fieldValue && fieldValue.trim() !== '')
    setCanSaveGlobalSettings(hasChanges)
  }, [watchFields])

  const getInputSettings = async () => {
    try {
      const resp = await getSettings()

      if (resp.success) {
        const translatedSettings = translateInputSettings(resp.data.systemValues)
        setInputSettings(translatedSettings)
        setLoading(false)
      } else {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Não foi possível carregar os dados, tente novamente mais tarde.'),
          errorMessage: resp.errorDetails,
          errors: resp.errors,
        })
      }
    } catch (error) {
      return (
        <ErrorToast message={t('Não foi possível carregar os dados, tente novamente mais tarde.')} />
      )
    }
  }

  const getInputSetValues = async () => {
    let siteId
    if (selectedSiteId !== '') {
      siteId = selectedSiteId
    } else {
      return
    }

    try {
      const resp = await getSiteValues({ siteId: siteId })
      if (resp.success) {
        setRespSiteValues(resp.data)
      } else {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Não foi possível carregar os dados, tente novamente mais tarde.'),
          errorMessage: resp.errorDetails,
          errors: resp.errors,
        })
      }
    } catch (error) {
      return (
        <ErrorToast message={t('Não foi possível carregar os dados, tente novamente mais tarde.')} />
      )
    }
  }

  const loadSites = async () => {
    try {
      const site = await getSimpleSite()
      if (site.success) {
        const sites = site.data
        const options = sites.map((site) => ({
          label: site.nameAndLocal,
          value: site.id,
        }))
        setSiteOptions(options)
      } else {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Não foi possível carregar os parques solares, tente novamente mais tarde.'),
          errorMessage: site.errorDetails,
          errors: site.errors,
        })
      }
    } catch (error) {
      return (
        <ErrorToast
          message={t('Não foi possível carregar as parques solares, tente novamente mais tarde.')}
        />
      )
    }
  }

  const handleFormatData = (values: GenericData): ValueToSend[] => {
    return inputSettings
      .map((setting) => {
        const currentValue = values[setting.key]
        let valueToSend: string

        if (setting.options) {
          const selectedOption = setting.options.find((option) => option.value === currentValue)
          valueToSend = selectedOption ? String(selectedOption.id) : ''
        } else {
          valueToSend = currentValue || setting.value || ''
        }

        return { id: setting.id, value: valueToSend }
      })
      .filter((item) => item.value !== '')
  }

  const onSubmit = async (values: GenericData) => {
    const datatoSend = handleFormatData(values)

    try {
      setLoading(true)
      const resp = await patchSettings(datatoSend)
      if (resp.success) {
        setCanSaveGlobalSettings(true)
        ToastNotification({
          id: 'success',
          type: 'success',
          message: t('Alterações realizadas com sucesso!'),
        })
      } else {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Não foi possível enviar os dados, tente novamente mais tarde.'),
          errorMessage: resp.errorDetails,
          errors: resp.errors,
        })
      }
    } catch (error) {
      return <ErrorToast message={t('Não foi possível enviar os dados, tente novamente mais tarde.')} />
    } finally {
      setLoading(false)
    }
  }

  const submitSiteSpecificData = async (values: GenericData) => {
    const dataToSend = collectSiteSpecificData(values)
    try {
      setLoadingSitio(true)
      const resp = await postSiteValues(dataToSend)

      if (resp) {
        ToastNotification({
          id: 'success',
          type: 'success',
          message: t('Alterações realizadas com sucesso !'),
        })
      } else {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Não foi possível enviar os dados, tente novamente mais tarde.'),
        })
      }
    } catch (error) {
      return <ErrorToast message={t('Não foi possível enviar os dados, tente novamente mais tarde.')} />
    } finally {
      setLoadingSitio(false)
    }
  }

  const translateInputSettings = (settings: InputSystemProps[]): InputSystemProps[] => {
    return settings.map((setting) => ({
      ...setting,
      initialValue: setting.value,
      displayName: t(`Settings.${setting.key}`),
      description: t(`SettingsDescriptions.${setting.key}`),
      options: setting.options?.map((option) => ({
        ...option,
        value: t(`CurrencyOptions.${option.value}`),
        description: option.description,
      })),
    }))
  }

  const collectSiteSpecificData = (values: GenericData): IRequestPostSiteValues => {
    const newCustomValues =
      respSiteValues?.customValues.map((cv) => {
        const fieldName = `site-${cv.key}`
        const currentValue = values[fieldName] ?? '0'
        return {
          key: cv.key,
          value: currentValue,
        }
      }) || []

    return {
      siteId: selectedSiteId,
      newCustomValues: newCustomValues,
    }
  }

  useEffect(() => {
    getInputSettings()
    loadSites()
  }, [])

  useEffect(() => {
    if (selectedSiteId) {
      getInputSetValues()
    } else {
      setRespSiteValues(null)
    }
  }, [selectedSiteId])

  const prepareFormValues = (siteValues: IResponseSiteValues | null): GenericData => {
    const formValues: GenericData = {}
    if (!siteValues?.customValues) return formValues

    siteValues.customValues.forEach((curr) => {
      const setting = inputSettings.find((is) => is.key === curr.key)
      if (setting) {
        const formattedValue = setting.id === 3 ? curr.value : curr.value || setting.value || ''
        formValues[`site-${curr.key}`] = formattedValue
      }
    })

    return formValues
  }

  useEffect(() => {
    const formValues = prepareFormValues(respSiteValues)
    reset(formValues)
  }, [respSiteValues, reset, inputSettings])

  const handleShowSetting = (
    inputValues: InputSystemProps,
    field: ControllerRenderProps<GenericData, string>,
  ) => {
    let selectedOption: IInputOptionsProps | undefined = undefined
    switch (Number(inputValues.type)) {
      case 1:
      case 2:
        return (
          <InputMaterial
            {...field}
            value={field.value ?? inputValues.value}
            size="small"
            id={inputValues.id.toString()}
            label={inputValues.displayName}
            sx={{ width: 500, mb: 2 }}
            disabled={user?.accessLevel !== 1}
          />
        )
      case 3:
        selectedOption = inputValues?.options?.filter((option) => {
          return field.value ? option.value === field.value : option.id === Number(inputValues.value)
        })[0]

        return (
          <FormControl variant="outlined" sx={{ width: 500 }}>
            <InputLabel id="select-label">{inputValues.displayName}</InputLabel>

            <Select
              {...field}
              labelId="select-label"
              value={selectedOption?.value}
              size="small"
              id={inputValues.id.toString()}
              label={inputValues.displayName}
              inputProps={{
                startAdornment: selectedOption?.description,
              }}
              disabled={user?.accessLevel !== 1}
              renderValue={(params) => {
                return (
                  <S.SelectValue>
                    {params} ({selectedOption?.description})
                  </S.SelectValue>
                )
              }}
            >
              {inputValues?.options?.map((option) => (
                <MenuItem value={option.value} key={option.id}>
                  {option.value} ({option.description})
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )

      default:
        break
    }
    return <></>
  }

  const generateSiteSpecificFields = () => {
    if (!respSiteValues) {
      return null
    }

    return respSiteValues.customValues.map((cv) => {
      const setting = inputSettings.find((is) => is.key === cv.key)
      if (!setting) {
        return null
      }

      const fieldName = `site-${cv.key}`
      const selectedOption =
        setting.id === 3 && setting.options
          ? setting.options.find((option) => option.id.toString() === cv.value)
          : null

      const computeDefaultValue = (): string => {
        if (setting.id === 3) {
          return selectedOption
            ? selectedOption.id.toString()
            : setting.options?.[0]?.id.toString() ?? ''
        }
        return ''
      }

      const computeValueToUse = (): string => {
        if (setting.id === 3) {
          if (cv.value) {
            return selectedOption ? selectedOption.id.toString() : defaultValue
          }
          return defaultValue
        }
        return cv.value || setting.value || ''
      }

      const defaultValue = computeDefaultValue()
      const valueToUse = computeValueToUse()

      return (
        <Controller
          key={fieldName}
          name={fieldName}
          control={control}
          defaultValue={valueToUse}
          render={({ field }) => (
            <S.InputWrapper>
              {setting.id === 3 ? (
                <FormControl variant="outlined" sx={{ width: 500, mt: 2 }}>
                  <InputLabel id={`select-label-${setting.key}`}>{setting.displayName}</InputLabel>
                  <Select
                    {...field}
                    labelId={`select-label-${setting.key}`}
                    value={String(field.value)}
                    onChange={(e) => field.onChange(String(e.target.value))}
                    size="small"
                    id={`site-specific-${setting.id}`}
                    label={setting.displayName}
                  >
                    {setting.options?.map((option) => (
                      <MenuItem key={option.id} value={option.id.toString()}>
                        {option.value} ({option.description})
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              ) : (
                <InputMaterial
                  {...field}
                  size="small"
                  id={`site-specific-${setting.id}`}
                  label={setting.displayName}
                  sx={{ width: 500, mt: 2 }}
                />
              )}
              <Tooltip title={setting.description}>
                <InfoIcon sx={{ mt: 2 }} color="primary" />
              </Tooltip>
            </S.InputWrapper>
          )}
        />
      )
    })
  }

  return (
    <Paper
      sx={{
        padding: 3,
        margin: '2px calc(10%)',
        backgroundColor: 'white',
        boxShadow: 1,
      }}
    >
      <S.Form onSubmit={handleSubmit(onSubmit)}>
        <Stack sx={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
          <Stack width={'50%'}>
            <Typography variant="h6" sx={{ mb: 2 }}>
              {t('Configuração Global')}
            </Typography>

            {inputSettings?.map((setting, index) => {
              if (setting)
                return (
                  <Controller
                    key={setting.key}
                    name={setting.key}
                    control={control}
                    render={({ field }) => (
                      <S.InputWrapper>
                        {handleShowSetting(setting, field)}
                        <Tooltip title={setting.description}>
                          <InfoIcon
                            color="primary"
                            sx={{ mb: index === inputSettings.length - 1 ? 0 : 2 }}
                          />
                        </Tooltip>
                      </S.InputWrapper>
                    )}
                  />
                )
            })}
            <Box sx={{ mt: 2 }}>
              <LoadingButton
                type="submit"
                onClick={handleSubmit(onSubmit)}
                loading={loading}
                variant="contained"
                disabled={!canSaveGlobalSettings}
              >
                {t('Salvar configurações Globais')}
              </LoadingButton>
            </Box>
          </Stack>

          <Stack width={'50%'}>
            <Box>
              <Typography variant="h6">{t('Configuração por Parque solar')}</Typography>
            </Box>

            <Box sx={{ width: '100%', display: 'flex', mt: 2 }}>
              <Autocomplete
                sx={{ width: 500 }}
                id="autocomplete-site"
                options={siteOptions}
                getOptionLabel={(option) => option.label}
                value={siteOptions.find((option) => option.value === selectedSiteId) || null}
                onChange={(event, newValue) => {
                  setSelectedSiteId(newValue ? newValue.value : '')
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t('Selecione o Parque solar')}
                    variant="outlined"
                    size="small"
                  />
                )}
              />
            </Box>
            {generateSiteSpecificFields()}

            <Box sx={{ mt: 2 }}>
              <LoadingButton
                onClick={handleSubmit(submitSiteSpecificData)}
                loading={loadingSitio}
                variant="contained"
                disabled={!isDirty}
              >
                {t('Salvar Configurações por Parque solar')}
              </LoadingButton>
            </Box>
          </Stack>
        </Stack>
      </S.Form>
    </Paper>
  )
}

export default Geral
