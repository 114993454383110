export interface ICompaniesAndBranchesList {
  id?: string
  companyBranch?: string
  cnpj?: string
  corporateName?: string
  fantasyName?: string
  status?: number
  state?: string
  city?: string
  neighborhood?: string
  place?: string
  number?: string
  complement?: string
  street?: string
}

export class IFilter {
  search? = ''
  typeOfCompany? = ''
  statusCompany? = ''
  id? = ''
}

export interface ICompaniesAndBranchesGet {
  cnpj: string | null
  corporateName: string
  fantasyName: string
  status: string
  state: string
  city: string
  neighborhood: string
  street: string
  number: string
  complement: string
  headId: string
  email: string
  phone: string
  logoUrl: string
  isInspector: boolean
  isForeign: boolean
}

export interface ICompaniesSimple {
  id: string
  corporateName: string
  fantasyName: string
  linkedSites: any[]
}
