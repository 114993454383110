/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { deleteJson, getJson, postJson, putJson } from '../RequestService'
import { IInspectorCompanyRequest, IInspectorCompanyResponse } from './types'

const defaultRoot = 'inspectorcompany'

export const postInspectorCompany = async (data: FormData) => {
  let url = `${defaultRoot}`
  return await postJson<FormData, string>(url, data)
}

export const patchInspectorCompany = async (data: FormData) => {
  let url = `${defaultRoot}`
  return await putJson<FormData, string>(url, data)
}

export const deleteInspectorCompanyById = async (params: IInspectorCompanyRequest) => {
  let url = `${defaultRoot}`
  if (params?.id) url += `?Id=${params.id}`
  return await deleteJson<boolean, boolean>(url)
}

export const getInspectorCompanyById = async (params: IInspectorCompanyRequest) => {
  let url = `${defaultRoot}`
  if (params?.id) url += `/${params.id}`
  if (params?.withLogoIfExists) url += `?WithLogoIfExists=${params.withLogoIfExists}`
  return await getJson<IInspectorCompanyResponse>(url)
}

export const getInspectorCompanySimple = async (params: IInspectorCompanyRequest) => {
  let url = `${defaultRoot}/simple?`
  if (params?.filter) url += `Filter=${params.filter}&`
  if (params?.pageNumber) url += `PageNumber=${params.pageNumber}&`
  if (params?.pageSize) url += `PageSize=${params.pageSize}&`
  if (params?.onlyOwned) url += `OnlyOwned=${params.onlyOwned}`
  return await getJson<IInspectorCompanyResponse[]>(url)
}
