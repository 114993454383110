import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { useMemo } from 'react'
import uuid from 'react-uuid'
import { StyledTableRow } from '~/utils'
import { MaterialTableProps } from './types'

const papperStyle = {
  padding: '20px',
  paddingTop: '10px',
  paddingBottom: 0,
}

function MaterialTable<T>({
  tableValues,
  renderRow,
  headCells,
  pagination,
  page,
}: Readonly<MaterialTableProps<T>>) {
  const emptyRows = 8 - tableValues.length

  const visibleRows = useMemo(() => {
    if (tableValues) return tableValues.slice(0, 10)
    return []
  }, [page, tableValues])

  return (
    <Paper sx={papperStyle}>
      <TableContainer>
        <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size="medium">
          <TableHead>
            <TableRow>
              {headCells.map((headCell, i) => (
                <TableCell
                  key={headCell.value}
                  align={headCell.alignment ?? 'right'}
                  padding="normal"
                  sx={{
                    fontWeight: 'bold',
                    backgroundColor: '#dbe1ee',
                    borderStartEndRadius: i === headCells.length - 1 ? '4px' : '0',
                    borderStartStartRadius: i === 0 ? '4px' : '0',
                    width: headCell.width ?? 'auto',
                  }}
                >
                  {headCell.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {visibleRows.map((row) => {
              return (
                <StyledTableRow hover tabIndex={-1} key={uuid()} sx={{ cursor: 'pointer' }}>
                  {renderRow(row)}
                </StyledTableRow>
              )
            })}
            {emptyRows > 0 && (
              <StyledTableRow
                style={{
                  height: 63 * emptyRows,
                }}
              >
                <TableCell colSpan={6} />
              </StyledTableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {pagination ? pagination() : <></>}
    </Paper>
  )
}

export default MaterialTable
