import { Delete, Edit } from '@mui/icons-material'
import { Box, Button, Grid, IconButton, TextField } from '@mui/material'
import { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import noImagePlaceholder from '~/assets/images/no-image-placeholder.png'
import ToastNotification from '~/components/ToastNotification'
import ViewError from '~/components/ViewError'
import ViewLoading from '~/components/ViewLoading'
import BaseLayout from '~/Layouts/BaseLayout'
import {
  getInspectorCompanyById,
  patchInspectorCompany,
  postInspectorCompany,
} from '~/services/InspectorCompany'
import * as S from '../RegisterAndEditCompaniesAndBranches/styles'
import { FormRegistration } from './types'

export default function RegisterAndEditInspectorCompanies() {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { token } = useParams()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)

  const {
    control,
    formState: { errors },
    watch,
    setValue,
    handleSubmit,
  } = useForm<FormRegistration>({
    defaultValues: {
      id: null,
      creatorCompanyId: null,
      logo: null,
      logoUrl: null,
      deleteLogo: false,
      name: null,
      email: null,
      thermographer: null,
      assistants: null,
    },
  })

  const onSubmit = async (data: FormRegistration) => {
    const formData = new FormData()
    Object.entries(data).forEach(([key, value]) => {
      if (value instanceof FileList) {
        Array.from(value).forEach((file) => formData.append(key, file))
      } else if (value === null) {
        formData.append(key, '')
      } else {
        formData.append(key, value as string)
      }
    })

    try {
      let response
      if (token) response = await patchInspectorCompany(formData)
      else response = await postInspectorCompany(formData)
      if (response.success) {
        ToastNotification({
          id: 'success',
          type: 'success',
          message: t('Os dados foram adicionados com sucesso!'),
        })
        navigate('/empresas-inspetoras')
      }
    } catch (error) {
      console.error(error)
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Não foi possível carregar os dados, tente novamente mais tarde.'),
        errorMessage: error as any,
        errors: error as any,
      })
    }
  }

  const fetchData = async () => {
    setLoading(true)
    try {
      const response = await getInspectorCompanyById({ id: token, withLogoIfExists: true })
      if (response.success) {
        setValue('id', response.data.id ?? null)
        setValue('creatorCompanyId', response.data.creatorCompanyId ?? null)
        setValue('logoUrl', response.data.logoUrl ?? null)
        setValue('name', response.data.name ?? null)
        setValue('email', response.data.email ?? null)
        setValue('thermographer', response.data.thermographer ?? null)
        setValue('assistants', response.data.assistants ?? null)
      }
    } catch (error) {
      console.error(error)
      setError(true)
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Não foi possível carregar os dados, tente novamente mais tarde.'),
        errorMessage: error as any,
        errors: error as any,
      })
    }
    setLoading(false)
  }

  const handleImageChange = (event: any) => {
    const file = event.target.files[0]
    if (file) {
      const objectUrl = URL.createObjectURL(file)
      setValue('logo', file)
      setValue('logoUrl', objectUrl)
      setValue('deleteLogo', false)
    }
  }

  useEffect(() => {
    if (token) fetchData()
  }, [])

  const handleBack = () => {
    navigate('/empresas-inspetoras')
  }

  return (
    <BaseLayout title={token ? t('Editar Inspetora') : t('Cadastrar Inspetora')}>
      <S.FlexWrapper>
        {loading && <ViewLoading />}
        {error && (
          <ViewError message={t('Não foi possível carregar os dados, tente novamente mais tarde.')} />
        )}
        {!loading && (
          <S.ContainerForm>
            <S.TextForm>
              {t(
                "Para concluir o processo, precisamos que você informe corretamente os dados e depois clique em 'Salvar'. É importante revisar as informações antes de salvar para garantir que tudo esteja correto.",
              )}
            </S.TextForm>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                padding: 2,
              }}
            >
              <Box
                sx={{
                  position: 'relative',
                  width: '200px',
                  height: '200px',
                  objectFit: 'fill',
                  borderRadius: '10px',
                  border: '2px solid #e9e9e9',
                }}
              >
                <Box
                  component="img"
                  src={(watch('logoUrl') as string) ?? noImagePlaceholder}
                  alt="Logo da empresa"
                  sx={{
                    width: '200px',
                    height: '200px',
                    objectFit: 'fill',
                    borderRadius: '10px',
                    border: '2px solid #e9e9e9',
                  }}
                />
                <IconButton
                  component="label"
                  sx={{
                    position: 'absolute',
                    top: 10,
                    right: 60,
                    backgroundColor: 'white', // White background
                    '&:hover': {
                      backgroundColor: '#f1f1f1', // Light gray on hover
                    },
                    borderRadius: '50%',
                    padding: 1,
                  }}
                >
                  <Edit sx={{ color: 'blue' }} /> {/* Blue icon color */}
                  <input type="file" hidden accept="image/*" onChange={handleImageChange} />
                </IconButton>

                <IconButton
                  onClick={() => {
                    setValue('logo', null)
                    setValue('logoUrl', null)
                    setValue('deleteLogo', true)
                  }}
                  sx={{
                    position: 'absolute',
                    top: 10,
                    right: 10,
                    backgroundColor: 'white', // White background
                    '&:hover': {
                      backgroundColor: '#f1f1f1', // Light gray on hover
                    },
                    borderRadius: '50%',
                    padding: 1,
                  }}
                >
                  <Delete sx={{ color: 'red' }} />
                </IconButton>
              </Box>
            </Box>

            <Box sx={{ margin: '0 auto', padding: 3 }}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Controller
                      name="name"
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label={t('Nome')}
                          fullWidth
                          size="small"
                          error={!!errors.name}
                          helperText={errors.name && t('Nome é obrigatório')}
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Controller
                      name="email"
                      control={control}
                      rules={{ pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i }}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label={t('Email')}
                          fullWidth
                          size="small"
                          type="email"
                          error={!!errors.email}
                          helperText={errors.email && t('Insira um email válido')}
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Controller
                      name="thermographer"
                      control={control}
                      rules={{ maxLength: 256 }}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label={t('Termografista')}
                          fullWidth
                          size="small"
                          error={!!errors.thermographer}
                          helperText={errors.thermographer && t('Máximo de 256 caracteres')}
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Controller
                      name="assistants"
                      control={control}
                      rules={{ maxLength: 256 }}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label={t('Auxiliar')}
                          fullWidth
                          size="small"
                          error={!!errors.assistants}
                          helperText={errors.assistants && t('Máximo de 256 caracteres')}
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={6} style={{ marginTop: '16px' }}>
                    <Button variant="outlined" color="primary" fullWidth onClick={handleBack}>
                      {t('Cancelar')}
                    </Button>
                  </Grid>
                  <Grid item xs={6} style={{ marginTop: '16px' }}>
                    <Button variant="contained" color="primary" type="submit" fullWidth>
                      {t('Salvar')}
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Box>
          </S.ContainerForm>
        )}
      </S.FlexWrapper>
    </BaseLayout>
  )
}
