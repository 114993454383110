/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { RequestUsers, IUser, IUserSimple, IUserAcessLevel } from './types'
import { deleteJson, getJson, postJson, putJson } from '../RequestService'
import { IUserGet, IUsersList } from '~/pages/Dashboard/Users/types'

const defaultRoot = 'user'

export const getUsers = async (data: RequestUsers) => {
  let url = `${defaultRoot}`
  url += `?PageNumber=${data?.page}&PageSize=${data?.pageSize}`
  if (data?.search) url += `&Name=${data.search}`
  if (data?.statusUser) url += `&Status=${data.statusUser}`
  if (data.accessLevel !== undefined && data.accessLevel !== '')
    url += `&accessLevel=${data.accessLevel}`
  if (data?.id) url += `&Id=${data.id}`
  if (data?.companyId) url += `&CompanyId=${data.companyId}`

  return await getJson<IUsersList[]>(url)
}

export const getUser = async (id?: string) => {
  let url = `${defaultRoot}/${id}`
  return await getJson<IUserGet>(url)
}

export const deleteUser = async (id: string) => {
  let url = `${defaultRoot}?UserId=${id}`
  return await deleteJson<boolean, boolean>(url)
}

export const postUser = async (data: IUser) => {
  let url = `${defaultRoot}`
  return await postJson<IUser, boolean>(url, data)
}

export const putUser = async (data: IUser, id: string) => {
  let url = `${defaultRoot}`
  return await putJson<IUser, boolean>(url, data)
}

export const getUserSimple = async (OnlyActive?: boolean) => {
  let url = `${defaultRoot}/simple`
  if (OnlyActive) url += `&OnlyActive=${OnlyActive}`

  return await getJson<IUserSimple>(url)
}

export const getAcessLevel = async () => {
  let url = `${defaultRoot}/accesslevels`
  return await getJson<IUserAcessLevel[]>(url)
}
