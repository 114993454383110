import AppRegistrationIcon from '@mui/icons-material/AppRegistration'
import MaterialAssignmentInd from '@mui/icons-material/AssignmentInd'
import BarChartIcon from '@mui/icons-material/BarChart'
import HomeRepairServiceIcon from '@mui/icons-material/HomeRepairService'
import MaterialSettingsIcon from '@mui/icons-material/Settings'
import { theme } from '~/styles/theme'
import { IconProps, IconPropsDefault } from './types'

const defaultValues: IconProps = {
  fill: '#000',
  style: {},
  width: 24,
  height: 24,
}

const adjIconProps = (props: IconProps): IconProps => {
  return {
    fill: props.fill ?? defaultValues.fill,
    style: props.style ?? defaultValues.style,
    width: props.width ?? defaultValues.width,
    height: props.height ?? defaultValues.height,
    onClick: props.onClick,
    onMouseEnter: props.onMouseEnter,
    onMouseLeave: props.onMouseLeave,
  }
}

const IconNone = (props: IconProps) => (
  <svg viewBox="0 0 192 192" {...adjIconProps(props)}>
    <circle cx="96" cy="96" r="96" fill="#757575" />
    <path fill="#bdbdbd" d="M29 29h134v134H29z" />
    <path fill="#fff" d="M163 29L96 163 29 29h134z" />
  </svg>
)

const Home = (props: IconProps) => (
  <svg viewBox="0 0 24 24" {...adjIconProps(props)}>
    <path
      d="M5 12H3L12 3L21 12H19"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5 12V19C5 19.5304 5.21071 20.0391 5.58579 20.4142C5.96086 20.7893 6.46957 21 7 21H17C17.5304 21 18.0391 20.7893 18.4142 20.4142C18.7893 20.0391 19 19.5304 19 19V12"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9 21V15C9 14.4696 9.21071 13.9609 9.58579 13.5858C9.96086 13.2107 10.4696 13 11 13H13C13.5304 13 14.0391 13.2107 14.4142 13.5858C14.7893 13.9609 15 14.4696 15 15V21"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

const SolarPanel = (props: IconProps) => (
  <svg viewBox="0 0 20 20" {...adjIconProps(props)}>
    <path d="M18 2H16.6667M16.6667 2H14V1H14.6667H16.6667V2Z" stroke="white" />
    <path d="M2 2H6V1H3.33333" stroke="white" />
    <path d="M8 2V1H12V2H8Z" stroke="white" />
    <path
      d="M1 9H3.66667M3.66667 9H5V5H3.66667M3.66667 9L3 8L2.33333 8.33333V7.66667V5H3.66667M3.66667 5V6.33333V7.66667V8.5"
      stroke="white"
    />
    <path
      d="M6 19H10M14 19H10M10 19V11M10 11H7.14286V13.6667H12.8571V11H10ZM8.28571 12.3333H8.85714H9.42857H7.71429H12.2857M7.5 12L9.42857 11.4463H9.5696"
      stroke="white"
    />
    <path d="M16.3333 12.5H17.6667H18.3333H15.6667M15 11V14H19V11H15Z" stroke="white" />
    <path
      d="M2.33333 12.5H3H4.33333H1.66667M1.5 11.5L1.66667 13.2889L4 13.5L4.33333 13.2889L4.21312 11.7187L3.5 11.5H2.33333L1.66667 11.586M1 11V14H5V11H1Z"
      stroke="white"
    />
    <path
      d="M19 9H17.6667M17.6667 9V7.66667V5H16.3333M17.6667 9H16.3333M16.3333 9H15V5H16.3333M16.3333 9V5"
      stroke="white"
    />
    <path
      d="M7 9H8.2M13 9H11.8M8.2 9V5H9.4M8.2 9H9.4M11.8 9V8.33333V5H10.6M11.8 9H10.6M9.4 9V5M9.4 9H10.6M9.4 5H10.6M10.6 5V9"
      stroke="white"
    />
    <path d="M12 13H7.45459L7.5 11.5H12L12 13Z" stroke="white" />
    <path d="M16 11V13H18.5V11H16.5" stroke="white" />
    <path d="M3 5V7.5L4 8V5" stroke="white" />
    <path
      d="M9 5V8H10.3333L10 5H10.6667H11C11 5.33333 11 6.1 11 6.5C11 6.9 11 7.33333 11 7.5V8L10 7.5L9.66667 7"
      stroke="white"
    />
    <path d="M16 5V8H17V5" stroke="white" />
    <path d="M10.5 12L12 11.5" stroke="white" />
    <path d="M17 11.5V12H18L18.5 11.5L17.5 13" stroke="white" />
  </svg>
)

const Search = (props: IconProps) => (
  <svg viewBox="0 0 24 24" {...adjIconProps(props)}>
    <path
      d="M10 17C13.866 17 17 13.866 17 10C17 6.13401 13.866 3 10 3C6.13401 3 3 6.13401 3 10C3 13.866 6.13401 17 10 17Z"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21 21L15 15"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

const Graphic = (props: IconProps) => (
  <svg viewBox="0 0 24 24" {...adjIconProps(props)}>
    <path d="M6 18V15" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M10 18V12" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M14 18V9" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M18 18V6" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
)

const Bell = (props: IconProps) => (
  <svg viewBox="0 0 24 24" {...adjIconProps(props)}>
    <path
      d="M10 5C10 4.46957 10.2107 3.96086 10.5858 3.58579C10.9609 3.21071 11.4696 3 12 3C12.5304 3 13.0391 3.21071 13.4142 3.58579C13.7893 3.96086 14 4.46957 14 5C15.1484 5.54303 16.1274 6.38833 16.8321 7.4453C17.5367 8.50227 17.9404 9.73107 18 11V14C18.0753 14.6217 18.2954 15.2171 18.6428 15.7381C18.9902 16.2592 19.4551 16.6914 20 17H4C4.54494 16.6914 5.00981 16.2592 5.35719 15.7381C5.70457 15.2171 5.92474 14.6217 6 14V11C6.05956 9.73107 6.4633 8.50227 7.16795 7.4453C7.8726 6.38833 8.85159 5.54303 10 5"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9 17V18C9 18.7956 9.31607 19.5587 9.87868 20.1213C10.4413 20.6839 11.2044 21 12 21C12.7956 21 13.5587 20.6839 14.1213 20.1213C14.6839 19.5587 15 18.7956 15 18V17"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

const Person = (props: IconProps) => (
  <svg viewBox="0 0 24 24" {...adjIconProps(props)}>
    <path
      d="M12 11C14.2091 11 16 9.20914 16 7C16 4.79086 14.2091 3 12 3C9.79086 3 8 4.79086 8 7C8 9.20914 9.79086 11 12 11Z"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6 21V19C6 17.9391 6.42143 16.9217 7.17157 16.1716C7.92172 15.4214 8.93913 15 10 15H14C15.0609 15 16.0783 15.4214 16.8284 16.1716C17.5786 16.9217 18 17.9391 18 19V21"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

const Loggout = (props: IconProps) => (
  <svg viewBox="0 0 24 24" {...adjIconProps(props)}>
    <path
      d="M14 8V6C14 5.46957 13.7893 4.96086 13.4142 4.58579C13.0391 4.21071 12.5304 4 12 4H5C4.46957 4 3.96086 4.21071 3.58579 4.58579C3.21071 4.96086 3 5.46957 3 6V18C3 18.5304 3.21071 19.0391 3.58579 19.4142C3.96086 19.7893 4.46957 20 5 20H12C12.5304 20 13.0391 19.7893 13.4142 19.4142C13.7893 19.0391 14 18.5304 14 18V16"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20 12H6H17.5H20ZM20 12L17 15"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20 12H6H17.5H20ZM20 12L17 9"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

const ArrowBack = (props: IconProps) => (
  <svg viewBox="0 0 24 24" {...adjIconProps(props)}>
    <path d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z" />
  </svg>
)

const ArrowForward = (props: IconProps) => (
  <svg viewBox="0 0 24 24" {...adjIconProps(props)}>
    <path d="M12 4l-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8-8-8z" />
  </svg>
)

const Warning = (props: IconProps) => (
  <svg viewBox="0 0 24 24" {...adjIconProps(props)}>
    <path d="M1 21h22L12 2 1 21zm12-3h-2v-2h2v2zm0-4h-2v-4h2v4z" />
  </svg>
)

const CurvedLine = (props: IconProps) => (
  <svg viewBox="0 0 40 40" {...adjIconProps(props)}>
    <path
      d="M20 20C20 20.6566 19.8707 21.3068 19.6194 21.9134C19.3681 22.52 18.9998 23.0712 18.5355 23.5355C18.0712 23.9998 17.52 24.3681 16.9134 24.6194C16.3068 24.8707 15.6566 25 15 25C14.3434 25 13.6932 24.8707 13.0866 24.6194C12.48 24.3681 11.9288 23.9998 11.4645 23.5355C11.0002 23.0712 10.6319 22.52 10.3806 21.9134C10.1293 21.3068 10 20.6566 10 20H10.9948C10.9948 20.526 11.0984 21.0468 11.2997 21.5327C11.5009 22.0187 11.796 22.4602 12.1679 22.8321C12.5398 23.204 12.9813 23.4991 13.4673 23.7003C13.9532 23.9016 14.474 24.0052 15 24.0052C15.526 24.0052 16.0468 23.9016 16.5327 23.7003C17.0187 23.4991 17.4602 23.204 17.8321 22.8321C18.204 22.4602 18.4991 22.0187 18.7003 21.5327C18.9016 21.0468 19.0052 20.526 19.0052 20H20Z"
      fill="black"
    />
    <path
      d="M19 20C19 19.3434 19.1293 18.6932 19.3806 18.0866C19.6319 17.48 20.0002 16.9288 20.4645 16.4645C20.9288 16.0002 21.48 15.6319 22.0866 15.3806C22.6932 15.1293 23.3434 15 24 15C24.6566 15 25.3068 15.1293 25.9134 15.3806C26.52 15.6319 27.0712 16.0002 27.5355 16.4645C27.9998 16.9288 28.3681 17.48 28.6194 18.0866C28.8707 18.6932 29 19.3434 29 20H28.0052C28.0052 19.474 27.9016 18.9532 27.7003 18.4673C27.4991 17.9813 27.204 17.5398 26.8321 17.1679C26.4602 16.796 26.0187 16.5009 25.5327 16.2997C25.0468 16.0984 24.526 15.9948 24 15.9948C23.474 15.9948 22.9532 16.0984 22.4673 16.2997C21.9813 16.5009 21.5398 16.796 21.1679 17.1679C20.796 17.5398 20.5009 17.9813 20.2997 18.4673C20.0984 18.9532 19.9948 19.474 19.9948 20H19Z"
      fill="black"
    />
  </svg>
)

const Rain = (props: IconProps) => (
  <svg viewBox="0 0 40 40" {...adjIconProps(props)}>
    <path
      d="M22.5 14L17 20.5H20L18.5 25.5L23.5 19H20.5L22.5 14Z"
      fill="black"
      stroke="black"
      strokeWidth="0.5"
    />
  </svg>
)

const TrendingDown = (props: IconProps) => (
  <svg viewBox="0 0 24 24" {...adjIconProps(props)}>
    <path d="M16 18l2.29-2.29-4.88-4.88-4 4L2 7.41 3.41 6l6 6 4-4 6.3 6.29L22 12v6h-6z" />
  </svg>
)

const MoreMinus = (props: IconProps) => (
  <svg viewBox="0 0 40 40" {...adjIconProps(props)}>
    <path
      d="M13 16.5V23.5"
      stroke="black"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.5 20H16.5"
      stroke="black"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M23.5 20H30.5"
      stroke="black"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

const Close = (props: IconProps) => (
  <svg viewBox="0 0 24 24" {...adjIconProps(props)}>
    <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z" />
  </svg>
)

const Visibility = (props: IconProps) => (
  <svg viewBox="0 0 24 24" {...adjIconProps(props)}>
    <path d="M12 4.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5zM12 17c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5zm0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z" />
  </svg>
)

const VisibilityOff = (props: IconProps) => (
  <svg viewBox="0 0 24 24" {...adjIconProps(props)}>
    <path d="M12 7c2.76 0 5 2.24 5 5 0 .65-.13 1.26-.36 1.83l2.92 2.92c1.51-1.26 2.7-2.89 3.43-4.75-1.73-4.39-6-7.5-11-7.5-1.4 0-2.74.25-3.98.7l2.16 2.16C10.74 7.13 11.35 7 12 7zM2 4.27l2.28 2.28.46.46C3.08 8.3 1.78 10.02 1 12c1.73 4.39 6 7.5 11 7.5 1.55 0 3.03-.3 4.38-.84l.42.42L19.73 22 21 20.73 3.27 3 2 4.27zM7.53 9.8l1.55 1.55c-.05.21-.08.43-.08.65 0 1.66 1.34 3 3 3 .22 0 .44-.03.65-.08l1.55 1.55c-.67.33-1.41.53-2.2.53-2.76 0-5-2.24-5-5 0-.79.2-1.53.53-2.2zm4.31-.78l3.15 3.15.02-.16c0-1.66-1.34-3-3-3l-.17.01z" />
  </svg>
)

const Info = (props: IconProps) => (
  <svg viewBox="0 0 24 24" {...adjIconProps(props)}>
    <path d="M11 7h2v2h-2zm0 4h2v6h-2zm1-9C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z" />
  </svg>
)

const Filter = (props: IconProps) => (
  <svg viewBox="0 0 24 24" {...adjIconProps(props)}>
    <path d="M7,6h10l-5.01,6.3L7,6z M4.25,5.61C6.27,8.2,10,13,10,13v6c0,0.55,0.45,1,1,1h2c0.55,0,1-0.45,1-1v-6 c0,0,3.72-4.8,5.74-7.39C20.25,4.95,19.78,4,18.95,4H5.04C4.21,4,3.74,4.95,4.25,5.61z" />
  </svg>
)

const Delete = (props: IconProps) => (
  <svg viewBox="0 0 24 24" {...adjIconProps(props)}>
    <path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM8 9h8v10H8V9zm7.5-5l-1-1h-5l-1 1H5v2h14V4z" />
  </svg>
)

const Edit = (props: IconProps) => (
  <svg viewBox="0 0 24 24" {...adjIconProps(props)}>
    <path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z" />
  </svg>
)

const Email = (props: IconProps) => (
  <svg viewBox="0 0 24 24" {...adjIconProps(props)}>
    <path d="M20,4H4C2.9,4,2,4.9,2,6v12c0,1.1,0.9,2,2,2h9v-2H4V8l8,5l8-5v5h2V6C22,4.9,21.1,4,20,4z M12,11L4,6h16L12,11z M19,15l4,4 l-4,4v-3h-4v-2h4V15z" />
  </svg>
)

const Add = (props: IconProps) => (
  <svg viewBox="0 0 24 24" {...adjIconProps(props)}>
    <path d="M0 0h24v24H0V0z" fill="none" />
    <path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z" />
  </svg>
)

const Description = (props: IconProps) => (
  <svg viewBox="0 0 24 24" {...adjIconProps(props)}>
    <path d="M0 0h24v24H0V0z" fill="none" />
    <path d="M8 16h8v2H8zm0-4h8v2H8zm6-10H6c-1.1 0-2 .9-2 2v16c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8l-6-6zm4 18H6V4h7v5h5v11z" />
  </svg>
)

const Business = (props: IconProps) => (
  <svg viewBox="0 0 24 24" {...adjIconProps(props)}>
    <path d="M0 0h24v24H0V0z" fill="none" />
    <path d="M12 7V3H2v18h20V7H12zM6 19H4v-2h2v2zm0-4H4v-2h2v2zm0-4H4V9h2v2zm0-4H4V5h2v2zm4 12H8v-2h2v2zm0-4H8v-2h2v2zm0-4H8V9h2v2zm0-4H8V5h2v2zm10 12h-8v-2h2v-2h-2v-2h2v-2h-2V9h8v10zm-2-8h-2v2h2v-2zm0 4h-2v2h2v-2z" />
  </svg>
)

const Pause = (props: IconProps) => (
  <svg viewBox="0 0 24 24" {...adjIconProps(props)}>
    <path d="M0 0h24v24H0V0z" fill="none" />
    <path d="M6 19h4V5H6v14zm8-14v14h4V5h-4z" />
  </svg>
)

const PlayArrow = (props: IconProps) => (
  <svg viewBox="0 0 24 24" {...adjIconProps(props)}>
    <path d="M0 0h24v24H0V0z" fill="none" />
    <path d="M10 8.64L15.27 12 10 15.36V8.64M8 5v14l11-7L8 5z" />
  </svg>
)

const FileUpload = (props: IconProps) => (
  <svg viewBox="0 0 24 24" {...adjIconProps(props)}>
    <path d="M0 0h24v24H0V0z" fill="none" />
    <path d="M18,15v3H6v-3H4v3c0,1.1,0.9,2,2,2h12c1.1,0,2-0.9,2-2v-3H18z M7,9l1.41,1.41L11,7.83V16h2V7.83l2.59,2.58L17,9l-5-5L7,9z" />
  </svg>
)

const MapIcon = (props: IconProps) => (
  <svg viewBox="0 0 24 24" {...adjIconProps(props)}>
    <path
      d="M21 10L12 1 3 10"
      stroke={props.fill}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21 10v11a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V10M21 10H3"
      stroke={props.fill}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M14 10h7v11h-7V10zM3 10h7v11H3V10z" fill={props.fill} />
    <circle cx="12" cy="12" r="3" fill={props.fill} />
  </svg>
)

const Anchor = (props: IconProps) => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...adjIconProps(props)}
  >
    <path
      d="M24 29C19.5817 29 16.0274 25.4182 16 21C15.9724 16.5541 19.554 12.9 24 12.9C28.446 12.9 32.0276 16.5541 32 21C31.9726 25.4182 28.4183 29 24 29Z"
      fill={props.fill}
    />
    <path
      d="M24 29C19.5817 29 16.0274 25.4182 16 21C15.9724 16.5541 19.554 12.9 24 12.9C28.446 12.9 32.0276 16.5541 32 21C31.9726 25.4182 28.4183 29 24 29Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24 3C12.5 3 6 12.486 6 21C6 24.6367 7.23367 28.092 9.0559 31.1997C12 35.5 17 39.4785 23.5 45.5C23.8908 45.8305 24.1189 45.8581 24.5091 45.5C30.9291 39.4305 36 35.5543 38.6832 31.1997C40.6476 28.0116 42 24.5257 42 21C42 12.486 35.5 3 24 3ZM16 21C16.0274 25.4182 19.5817 29 24 29C28.4183 29 31.9726 25.4182 32 21C32.0276 16.5541 28.446 12.9 24 12.9C19.554 12.9 15.9724 16.5541 16 21Z"
      fill={props.fill}
    />
  </svg>
)

const SettingsIcon = (props: IconProps) => (
  <MaterialSettingsIcon {...adjIconProps(props)} style={{ fill: 'white' }} />
)

const AssignmentInd = (props: IconProps) => (
  <MaterialAssignmentInd {...adjIconProps(props)} style={{ fill: 'white' }} />
)

const BarChart = (props: IconProps) => (
  <BarChartIcon {...adjIconProps(props)} style={{ fill: props.fill ?? 'white' }} />
)

const Repair = (props: IconProps) => (
  <HomeRepairServiceIcon {...adjIconProps(props)} style={{ fill: 'white' }} />
)

const Registration = (props: IconProps) => (
  <AppRegistrationIcon {...adjIconProps(props)} style={{ fill: 'white' }} />
)

export const IconList = {
  IconNone,
  Home,
  SolarPanel,
  Search,
  Graphic,
  Bell,
  Person,
  Loggout,
  ArrowBack,
  ArrowForward,
  Warning,
  CurvedLine,
  Rain,
  TrendingDown,
  MoreMinus,
  Close,
  Visibility,
  VisibilityOff,
  Info,
  Filter,
  Delete,
  Edit,
  Email,
  Add,
  Description,
  Business,
  Pause,
  PlayArrow,
  FileUpload,
  SettingsIcon,
  AssignmentInd,
  BarChart,
  Anchor,
  MapIcon,
  Repair,
  Registration,
}

const Icon = (props: IconPropsDefault) => {
  const {
    name,
    fill = theme.colors.primary,
    height,
    width,
    style,
    onClick,
    onMouseEnter,
    onMouseLeave,
  } = props
  let SelectedIcon = IconList[name]
  if (!SelectedIcon) SelectedIcon = IconList.IconNone

  return (
    <div>
      <SelectedIcon
        fill={fill}
        style={style}
        width={width}
        height={height}
        onClick={onClick}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      />
    </div>
  )
}

export default Icon
