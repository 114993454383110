export class Registration {
  name = ''
  email = ''
  company = ''
  companyId = ''
  companyIds: string[] = []
  status = '1'
  accessLevel = 0
  password = ''
}

export class RegistrationError {
  name = ''
  email = ''
  company = ''
  companyId = ''
  companyIds = ''
  status = ''
  accessLevel = ''
  password = ''
}
