export class Registration {
  cnpj: string | null = null // Permitir string ou null
  corporateName = ''
  fantasyName = ''
  status = '1'
  state = ''
  city = ''
  neighborhood = ''
  publicPlace = ''
  number = ''
  complement = ''
  email = ''
  phone = ''
  image = ''
  isInspector = false
  isForeign = false
}

export class RegistrationError {
  cnpj: string | null = null
  corporateName = ''
  fantasyName = ''
  status = ''
  state = ''
  city = ''
  neighborhood = ''
  publicPlace = ''
  number = ''
  complement = ''
  email = ''
  phone = ''
  image = ''
  isInspector = false
  isForeign = false
}
