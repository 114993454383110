import { Box } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useAuth } from '~/contexts/Auth'
import { AccessLevel } from '~/utils/General'
import { CustomTypography } from '../Sites/ListInspections/components/customTypography'

export const AccessLevelInfoTooltip = () => {
  const { user } = useAuth()
  const { t } = useTranslation()

  const textSections = [
    { key: 'Leitura', bold: true, marginTop: '0px' },
    {
      key: 'Perfil indicado para: Visitantes e terceiros no qual somente precisam visualizar a inspeção, sem executar qualquer ação.',
      bold: false,
      marginTop: '0px',
    },
    { key: 'Básico', bold: true, marginTop: '8px' },
    {
      key: 'Perfil indicado para: Suporte (envio de imagens de inspeção), Secretaria, Administração básica da conta.',
      bold: false,
      marginTop: '0px',
    },
    { key: 'Admin', bold: true, marginTop: '8px' },
    {
      key: 'Perfil indicado para: Gerente, Diretor, tomador de decisão da conta e responsáveis pela parte operacional.',
      bold: false,
      marginTop: '0px',
    },
  ]

  if (user?.accessLevel === AccessLevel.Master) {
    textSections.push(
      { key: 'Master', bold: true, marginTop: '8px' },
      {
        key: 'Perfil indicado para: Proprietário do sistema e sócios. Apenas para uso exclusivo do sistema e com acesso total as funções de todos os usuários.',
        bold: false,
        marginTop: '0px',
      },
    )
  }

  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        wordWrap: 'break-word',
        whiteSpace: 'normal',
      }}
    >
      {textSections.map((section) => (
        <CustomTypography key={section.key} bold={section.bold} marginTop={section.marginTop}>
          {t(section.key)}
        </CustomTypography>
      ))}
    </Box>
  )
}
