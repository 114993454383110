import { getJson, postJson } from '../RequestService'
import { IRequestUserSite, IResponseUserSite } from './types'

const defaultRoot = 'usersite'

export const getUserSite = async (userId: string) => {
  let url = `${defaultRoot}`
  url += `?UserId=${userId}`
  return await getJson<IResponseUserSite[]>(url)
}

export const getUserSiteCompaniesEligibleSites = async (companyIds: string[]) => {
  let url = `${defaultRoot}/companieseligiblesites?`
  companyIds.forEach((id, index) => {
    url += `CompanyIds=${id}`
    if (index < companyIds.length - 1) url += '&'
  })
  return await getJson<IResponseUserSite[]>(url)
}

export const postUserSite = async (data: IRequestUserSite) => {
  let url = `${defaultRoot}`
  return await postJson<IRequestUserSite, IResponseUserSite>(url, data)
}
