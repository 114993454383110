import { TFunction } from 'i18next'
import * as yup from 'yup'

import { Registration, RegistrationError } from './types'

export const registerValidation = async (
  object: Registration,
  t: TFunction,
  context: { isEditing: boolean },
): Promise<true | RegistrationError> => {
  const schemaRegister = yup.object().shape({
    name: yup
      .string()
      .min(
        5,
        t('O nome deve ter pelo menos 5 caracteres.') ?? 'O nome deve ter pelo menos 5 caracteres.',
      )
      .max(
        256,
        t('O nome não pode exceder 256 caracteres.') ?? 'O nome não pode exceder 256 caracteres.',
      )
      .required(t('Nome é obrigatório.') ?? 'O nome é obrigatório.'),
    email: yup
      .string()
      .email(t('Informe um e-mail válido.') ?? 'Informe um e-mail válido.')
      .required(t('E-mail é obrigatório.') ?? 'O e-mail é obrigatório.'),
    status: yup.string().required(t('Status é obrigatório.') ?? 'O campo status é obrigatório.'),
    accessLevel: yup
      .string()
      .required(t('Nível de acesso é obrigatório.') ?? 'O campo nível de acesso é obrigatório.'),
    password: yup.string().when('$isEditing', {
      is: false,
      then: yup
        .string()
        .required(t('O campo senha é obrigatório.') ?? 'O campo senha é obrigatório.')
        .min(
          8,
          t('A senha deve ter pelo menos 8 caracteres.') ?? 'A senha deve ter pelo menos 8 caracteres.',
        )
        .matches(
          /[a-z]/,
          t('A senha deve conter pelo menos uma letra minúscula.') ??
            'A senha deve conter pelo menos uma letra minúscula.',
        )
        .matches(
          /[A-Z]/,
          t('A senha deve conter pelo menos uma letra maiúscula.') ??
            'A senha deve conter pelo menos uma letra maiúscula.',
        )
        .matches(
          /\d/,
          t('A senha deve conter pelo menos um número.') ?? 'A senha deve conter pelo menos um número.',
        )
        .matches(
          /[^a-zA-Z0-9]/,
          t('A senha deve conter pelo menos um caractere especial.') ??
            'A senha deve conter pelo menos um caractere especial.',
        ),
      otherwise: yup.string(),
    }),
    companyId: yup
      .string()
      .required(
        t('Mínimo de 1 empresa adicionada e 1 empresa principal.') ??
          'Mínimo de 1 empresa adicionada e 1 empresa principal.',
      ),
  })

  try {
    await schemaRegister.validate(object, { abortEarly: false, context })
    return true
  } catch (err) {
    if (err instanceof yup.ValidationError) {
      const errors: RegistrationError = new RegistrationError()
      err.inner.forEach((error) => {
        if (error.path && !errors[error.path]) {
          errors[error.path] = error.message
        }
      })
      return errors
    }
    throw err
  }
}
