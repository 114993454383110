import { Autocomplete, FormControl, TableCell, TablePagination, TextField } from '@mui/material'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { PAGE_SIZE } from '~/assets/Constants'
import Button from '~/components/Button'
import InputSearch from '~/components/InputSearch'
import ToastNotification from '~/components/ToastNotification'
import ViewError from '~/components/ViewError'
import ViewLoading from '~/components/ViewLoading'
import { deleteCompany, getCompaniesAndBranches } from '~/services/CompaniesAndBranches'
import responseTableUpdate from '~/utils/responseTableUpdate'
import { ICompaniesAndBranchesList, IFilter } from './types'

import { MaterialIcon } from '~/assets/icons/materialIcons'
import ButtonRemover from '~/components/ButtonDelete'
import { ButtonLink } from '~/components/ButtonLink'
import CustomButton from '~/components/CustomButton'
import MaterialTable from '~/components/Material/Table'
import ModalCustom from '~/components/ModalCustom'
import { useAuth } from '~/contexts/Auth'
import BaseLayout from '~/Layouts/BaseLayout'
import { renderStatus } from '~/utils/Mask'
import ErrorToast from '~/utils/toastErrorCatch'
import * as G from '../styles'
import * as S from './styles'
import { emptyItem } from './utils'

export default function CompaniesAndBranches() {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { token } = useParams()
  const [loading, setLoading] = useState(false)
  const [usersList, setUsersList] = useState<ICompaniesAndBranchesList[]>([])
  const [filters, setFilters] = useState<IFilter>(new IFilter())
  const [currentPage, setCurrentPage] = useState(1)
  const [itemSelected, setItemSelected] = useState<ICompaniesAndBranchesList>(emptyItem)
  const [openDeleteUserModal, setOpenDeleteUserModal] = useState(false)
  const [totalItens, setTotalItens] = useState<number | undefined>(0)

  useEffect(() => {
    if (token) {
      const body: IFilter = {
        id: token,
      }
      setFilters(body)
      loadCompaniesAndBranches(1, body, true)
    } else {
      loadCompaniesAndBranches(1, undefined, true)
    }
  }, [])

  const statusOptions = [
    { id: '1', name: t('Ativo') },
    { id: '2', name: t('Inativo') },
  ]

  const onEnterDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      loadCompaniesAndBranches(1, filters, true)
    }
  }

  const loadCompaniesAndBranches = async (
    page: number = 1,
    filter?: IFilter,
    clear: boolean = false,
  ) => {
    setLoading(true)
    try {
      if (clear) {
        setUsersList([])
      }

      const paginationAddresses = {
        page,
        pageSize: PAGE_SIZE,
        search: filter?.search,
        typeOfCompany: Number(filter?.typeOfCompany) !== 0 ? filter?.typeOfCompany : '',
        statusCompany: Number(filter?.statusCompany) !== 0 ? filter?.statusCompany : '',
        id: token,
      }

      const response = await getCompaniesAndBranches(paginationAddresses)

      if (response.success) {
        setCurrentPage(page)
        setTotalItens(response.itemsTotal)
        setUsersList(response.data)
      } else {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Não foi possível carregar os dados, tente novamente mais tarde.'),
          errorMessage: response.errorDetails,
          errors: response.errors,
        })
      }
    } catch (error) {
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Não foi possível carregar os dados, tente novamente mais tarde.'),
      })
    }
    setLoading(false)
  }
  const { user } = useAuth()

  const handleDeleteCompany = async () => {
    setLoading(true)
    try {
      if (itemSelected?.id) {
        const response = await deleteCompany(itemSelected.id)
        responseTableUpdate({
          isSuccess: response.success,
          successMessage: t('Empresa removida com sucesso!'),
          errorMessage: t('Não foi possível excluir esta empresa, tente novamente mais tarde.'),
          updateList: () => loadCompaniesAndBranches(1, filters, true),
          setOpenDeleteUserModal,
          errorDetails: response.errorDetails,
          errorsT: response.errors,
        })
      }
    } catch (error) {
      return (
        <ErrorToast message={t('Não foi possível excluir esta empresa, tente novamente mais tarde.')} />
      )
    }
    setLoading(false)
  }

  const headCells: {
    label: string
    value?: string
    alignment?: 'left' | 'center' | 'inherit' | 'right' | 'justify'
    width?: string
  }[] = [
    {
      label: t('Empresa'),
      value: 'corporateName',
      alignment: 'left',
    },
    {
      label: t('Logradouro'),
      value: 'street',
    },
    {
      label: t('Cidade'),
      value: 'city',
    },
    {
      label: t('Estado'),
      value: 'status',
    },
    {
      label: t('Status'),
      value: 'state',
    },
    {
      label: t('Ações'),
      value: '',
    },
  ]

  const handleEditClick = (companyId: string | undefined) => {
    navigate(`/edicao-de-empresas-e-filiais/${companyId}`, {
      state: {
        edit: true,
      },
    })
  }

  return (
    <BaseLayout
      title={t('Empresas')}
      extraContent={
        user?.accessLevel === 1 && (
          <CustomButton
            label={t('Cadastrar Empresa')}
            onClick={() => navigate(`/cadastro-de-empresas-e-filiais`)}
          />
        )
      }
    >
      <S.FlexWrapper>
        <G.ContainerFilter>
          <S.ContainerFilter>
            <S.ContainerSearch>
              <InputSearch
                placeholder={t('Pesquise por nome da empresa') ?? 'Pesquise por nome da empresa'}
                value={filters.search}
                onChange={(e) => setFilters({ ...filters, search: e.target.value })}
                onKeyDown={onEnterDown}
                variant="outlined"
                fullWidth
              />
            </S.ContainerSearch>
            <FormControl style={{ display: 'flex', width: '30%' }}>
              <Autocomplete
                id="autocomplete-status"
                options={statusOptions}
                getOptionLabel={(option) => option.name}
                value={statusOptions.find((option) => option.id === filters.statusCompany) ?? null}
                onChange={(event, newValue) => {
                  setFilters({ ...filters, statusCompany: newValue ? newValue.id : '' })
                }}
                renderInput={(params) => (
                  <TextField {...params} label={t('Status')} variant="outlined" size="small" />
                )}
              />
            </FormControl>
            <G.ContainerButton>
              <Button
                type="button"
                onClick={() => loadCompaniesAndBranches(1, filters, true)}
                label={t('Pesquisar')}
                style={{ marginTop: 0 }}
              />
            </G.ContainerButton>
          </S.ContainerFilter>
        </G.ContainerFilter>

        {loading ? (
          <ViewLoading />
        ) : (
          <G.Main>
            {usersList.length > 0 ? (
              <MaterialTable<ICompaniesAndBranchesList>
                headCells={headCells}
                page={currentPage - 1}
                tableValues={usersList.map((item) => ({
                  id: item?.id,
                  corporateName: item?.corporateName,
                  street: item?.street,
                  city: item?.city,
                  status: item?.status,
                  state: item?.state,
                }))}
                renderRow={(item) => {
                  return (
                    <>
                      <TableCell component="th" scope="row">
                        {item?.corporateName}
                      </TableCell>
                      <TableCell align="right">{item?.street}</TableCell>
                      <TableCell align="right">{item?.city}</TableCell>
                      <TableCell align="right">{t(item?.state ?? '')}</TableCell>
                      <TableCell align="right">{t(renderStatus(item?.status ?? 1))}</TableCell>
                      <TableCell align="right">
                        <S.Icons>
                          <ButtonLink
                            route=""
                            onClick={() => handleEditClick(item?.id)}
                            icon={<MaterialIcon name="Edit" title={t('Editar')} />}
                          />
                          {user?.accessLevel === 1 && (
                            <ButtonRemover<ICompaniesAndBranchesList>
                              item={item}
                              setItemSelected={setItemSelected}
                              setOpenDeleteUserModal={setOpenDeleteUserModal}
                              t={t}
                            />
                          )}
                        </S.Icons>
                      </TableCell>
                    </>
                  )
                }}
                pagination={() => (
                  <TablePagination
                    rowsPerPageOptions={[8]}
                    rowsPerPage={8}
                    component="div"
                    count={totalItens ?? 0}
                    page={currentPage - 1}
                    onPageChange={(e, p) => {
                      e?.preventDefault()
                      e?.stopPropagation()
                      loadCompaniesAndBranches(p + 1, filters)
                    }}
                  />
                )}
              />
            ) : (
              <ViewError message={t('Nenhuma empresa encontrada')} />
            )}
            <ModalCustom
              open={openDeleteUserModal}
              onClose={() => setOpenDeleteUserModal(false)}
              onClick={() => {
                handleDeleteCompany()
              }}
              loading={loading}
              title={t('Remover Empresa')}
              description={t(
                'Tem certeza de que deseja remover esta empresa? Após a confirmação, não há como voltar atrás.',
              )}
              confirmationButtonText={t('Excluir')}
              textDeclineButton={t('Cancelar')}
            />
          </G.Main>
        )}
      </S.FlexWrapper>
    </BaseLayout>
  )
}
