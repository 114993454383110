import DeleteIcon from '@mui/icons-material/Delete'
import InfoIcon from '@mui/icons-material/Info'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import {
  Autocomplete,
  Box,
  Checkbox,
  Collapse,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Radio,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
} from '@mui/material'
import { Fragment, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import Button from '~/components/Button'
import ModalCustom from '~/components/ModalCustom'
import ToastNotification from '~/components/ToastNotification'
import ViewError from '~/components/ViewError'
import ViewLoading from '~/components/ViewLoading'
import { IBaseResponse } from '~/services/RequestService/types'
import { getAcessLevel, getUser, postUser, putUser } from '~/services/Users'
import { Registration, RegistrationError } from './types'

import { useAuth } from '~/contexts/Auth'
import BaseLayout from '~/Layouts/BaseLayout'
import { getCompanySimple } from '~/services/CompaniesAndBranches'
import { IUserAcessLevel } from '~/services/Users/types'
import { getUserSite, getUserSiteCompaniesEligibleSites, postUserSite } from '~/services/UserSite'
import { AccessLevel } from '~/utils/General'
import handleResponse from '~/utils/responseEdit'
import ErrorToast from '~/utils/toastErrorCatch'
import { ICompaniesSimple } from '../CompaniesAndBranches/types'
import { AccessLevelInfoTooltip } from './AccessLevelInfoTooltip'
import * as S from './styles'
import { registerValidation } from './validations'

export default function RegisterAndEditUser() {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { token } = useParams()
  const { user } = useAuth()

  const [openConfirmationModal, setOpenConfirmationModal] = useState(false)

  const [loading, setLoading] = useState(false)
  const [registration, setRegistration] = useState<Registration>(new Registration())
  const [registrationError, setRegistrationError] = useState<RegistrationError>(new RegistrationError())
  const [loadingData, setLoadingData] = useState(false)
  const [requestSuccess, setRequestSuccess] = useState(false)
  const [availableCompaniesToLink, setAvailableCompaniesToLink] = useState<ICompaniesSimple[]>([])
  const [selectedCompanyToLink, setSelectedCompanyToLink] = useState<ICompaniesSimple | null>(null)
  const [linkedCompaniesList, setLinkedCompaniesList] = useState<ICompaniesSimple[]>([])
  const [openRow, setOpenRow] = useState<any>(null)

  const [accessLevels, setAccessLevels] = useState<IUserAcessLevel[]>([])

  const fetchAccessLevels = async () => {
    try {
      const levels = await getAcessLevel()
      const translatedLevels = translateAccessLevels(levels.data)
      setAccessLevels(translatedLevels)
    } catch (error) {
      return <ErrorToast message={t('Não foi possível buscar os niveis de acesso.')} />
    }
  }

  useEffect(() => {
    fetchAccessLevels()
    token && loadUser()
  }, [])

  const loadUser = async () => {
    setLoadingData(true)
    try {
      const response = await getUser(token)
      if (response.success) {
        setRegistration({
          name: response.data.name,
          email: response.data.email,
          company: response.data.company,
          companyId: response.data.companyId,
          companyIds: response.data.companyIds,
          status: String(response.data.status),
          accessLevel: response.data.accessLevel,
          password: '',
        })
        setRequestSuccess(true)
      } else {
        setRequestSuccess(false)
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t(response.message),
          errorMessage: response.errorDetails,
          errors: response.errors,
        })
      }
    } catch (error) {
      return (
        <ErrorToast message={t('Não foi possível carregar os dados, tente novamente mais tarde.')} />
      )
    }
    setLoadingData(false)
  }

  const RegisterAndEditUser = async () => {
    setLoading(true)

    let isEditing = false

    if (token) {
      isEditing = true
    }
    const validationResult = await registerValidation(registration, t, { isEditing })

    if (validationResult === true) {
      try {
        const body = {
          id: token ?? '',
          name: registration.name,
          email: registration.email,
          status: Number(registration.status),
          accessLevel: Number(registration.accessLevel),
          password: registration.password,
          companyId: registration.companyId,
          companyIds: linkedCompaniesList.map((company) => company.id),
        }

        let response: IBaseResponse<boolean>
        if (token) response = await putUser(body, token)
        else response = await postUser(body)

        const allowedSites = linkedCompaniesList
          .flatMap((company) => company.linkedSites)
          .filter(({ allowed }) => allowed)
          .map((site) => site.siteId)
        const notAllowedSites = linkedCompaniesList
          .flatMap((company) => company.linkedSites)
          .filter(({ allowed }) => !allowed)
          .map((site) => site.siteId)

        let userId: string
        if (typeof response.data === 'string') userId = response.data
        else userId = token!

        await postUserSite({
          userId,
          siteIdsToAdd: allowedSites,
          siteIdsToRemove: notAllowedSites,
          returnUpdatedSites: false,
        })

        handleResponse(response, token, t, navigate)
      } catch (error) {
        console.error(error)
        ToastNotification({
          id: 'error',
          type: 'error',
          message: token
            ? t('Não foi possível realizar a edição, tente novamente mais tarde.')
            : t('Não foi possível realizar o cadastro, tente novamente mais tarde.'),
        })
      }
    } else {
      setRegistrationError(validationResult)
      console.error(registrationError)
    }

    setLoading(false)
  }

  const translateAccessLevels = (levels: IUserAcessLevel[]): IUserAcessLevel[] => {
    return levels.map((level) => ({
      ...level,
      name: t(`AccessLevels.${level.name}`),
      description: t(`AccessLevelDescriptions.${level.description}`),
    }))
  }

  const fetchLinkedCompaniesSites = async () => {
    const companiesData = await getCompanySimple({
      ConsiderUserCompany: true,
      OnlyHeadOffices: false,
    })

    const companiesWithLinkedSites = companiesData.data.map((company) => ({
      ...company,
      linkedSites: [],
    }))

    setAvailableCompaniesToLink(companiesWithLinkedSites)
    if (registration.companyIds.length === 0) return
    let tempResponseSites: any[] = []

    try {
      let response
      if (token) {
        response = await getUserSite(token ?? '')
      } else {
        response = await getUserSiteCompaniesEligibleSites(registration.companyIds)
      }
      if (response.success && companiesData.success) {
        tempResponseSites = response.data

        const tempLinkedCompanies = companiesData.data.reduce<any>((acc, company) => {
          if (registration.companyIds.includes(company.id)) {
            const linkedSites = tempResponseSites.filter((site) => site.companyId === company.id)
            acc.push({
              ...company,
              linkedSites,
            })
          }
          return acc
        }, [])

        setLinkedCompaniesList(tempLinkedCompanies)
      } else {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Não foi possível carregar os dados, tente novamente mais tarde.'),
        })
      }
    } catch (error) {
      console.error(error)
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Não foi possível carregar os dados, tente novamente mais tarde.'),
      })
    }
  }

  useEffect(() => {
    fetchLinkedCompaniesSites()
  }, [registration.companyIds])

  useEffect(() => {
    if (!token && availableCompaniesToLink.length === 1) {
      setRegistration({
        ...registration,
        companyId: availableCompaniesToLink[0].id,
        companyIds: [availableCompaniesToLink[0].id],
      })
    }
  }, [availableCompaniesToLink])

  let authAccess = token ? requestSuccess : true

  const updateCompanySites = (companyId: string, siteId: string, allowed: boolean) => {
    const updatedSites = linkedCompaniesList
      .find((company) => company.id === companyId)
      ?.linkedSites.map((site) => {
        if (site.siteId === siteId) {
          return { ...site, allowed }
        }
        return site
      })

    if (updatedSites) {
      setLinkedCompaniesList(
        linkedCompaniesList.map((company) => {
          if (company.id === companyId) {
            return { ...company, linkedSites: updatedSites }
          }
          return company
        }),
      )
    }
  }

  const handleCheckboxChange = (companyId: string, siteId: string) => {
    updateCompanySites(
      companyId,
      siteId,
      !linkedCompaniesList
        .find((company) => company.id === companyId)
        ?.linkedSites.find((site) => site.siteId === siteId)?.allowed,
    )
  }

  return (
    <BaseLayout title={token ? t('Editar Usuário') : t('Cadastrar Usuário')}>
      <S.FlexWrapper>
        {loadingData && <ViewLoading />}
        {!loadingData && authAccess ? (
          <S.ContainerForm>
            <S.TextForm>
              {t(
                "Para concluir o processo, precisamos que você informe corretamente os dados e depois clique em 'Salvar'. É importante revisar as informações antes de salvar para garantir que tudo esteja correto.",
              )}
            </S.TextForm>

            <S.ContainerInput>
              <TextField
                label={t('Nome*')}
                type="state"
                value={registration.name}
                onChange={(e) => setRegistration({ ...registration, name: e.target.value })}
                size="small"
                fullWidth
                error={!!registrationError.name}
                helperText={registrationError.name}
                onClick={() => setRegistrationError({ ...registrationError, name: '' })}
                style={{ marginTop: '24px' }}
              />

              <TextField
                label={t('E-mail*')}
                type="fantasyName"
                value={registration.email}
                onChange={(e) => setRegistration({ ...registration, email: e.target.value })}
                size="small"
                fullWidth
                error={!!registrationError.email}
                helperText={registrationError.email}
                onClick={() => setRegistrationError({ ...registrationError, email: '' })}
                style={{ marginTop: '24px' }}
              />

              <S.ContainerDoubleInput style={{ width: '100%', marginLeft: 'auto', marginRight: 'auto' }}>
                <FormControl style={{ display: 'flex', width: '100%', marginRight: '12px' }}>
                  <InputLabel id="demo-simple-select-helper-label">{t('Status*')}</InputLabel>
                  <Select
                    size="small"
                    label={t('Status*')}
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    value={registration.status}
                    onChange={(e) =>
                      setRegistration({ ...registration, status: String(e.target.value) })
                    }
                    onClick={() => setRegistrationError({ ...registrationError, status: '' })}
                    error={!!registrationError.status}
                    disabled={user?.id === token}
                  >
                    <MenuItem value={'1'}>{t('Ativo')}</MenuItem>
                    <MenuItem value={'2'}>{t('Inativo')}</MenuItem>
                  </Select>
                  <FormHelperText style={{ color: '#d32f2f' }}>
                    {registrationError.status}
                  </FormHelperText>
                </FormControl>

                <FormControl style={{ display: 'flex', width: '100%' }}>
                  {accessLevels && (
                    <Autocomplete
                      size="small"
                      id="autocomplete-access-level"
                      options={accessLevels}
                      getOptionLabel={(option) => option.name}
                      value={
                        accessLevels.find(
                          (level) => Number(level.id) === Number(registration.accessLevel),
                        ) ?? null
                      }
                      onChange={(event, newValue) => {
                        setRegistration({
                          ...registration,
                          accessLevel: newValue ? Number(newValue.id) : 0,
                        })
                      }}
                      disabled={user?.id === token}
                      renderInput={(params) => (
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <TextField
                            {...params}
                            label={t('Nível de acesso*')}
                            variant="outlined"
                            disabled={user?.id === token}
                          />
                          <Tooltip
                            sx={{ cursor: 'pointer' }}
                            title={<AccessLevelInfoTooltip />}
                            placement="bottom"
                            arrow
                            PopperProps={{
                              modifiers: [
                                {
                                  name: 'offset',
                                  options: {
                                    offset: [0, 4],
                                  },
                                },
                              ],
                              sx: {
                                '& .MuiTooltip-tooltip': {
                                  backgroundColor: '#EFF3F9',
                                  color: 'rgba(0, 0, 0, 0.87)',
                                },
                              },
                            }}
                          >
                            <InfoIcon fontSize="medium" color="info" style={{ marginLeft: 5 }} />
                          </Tooltip>
                        </div>
                      )}
                    />
                  )}
                </FormControl>
              </S.ContainerDoubleInput>

              {!token && (
                <TextField
                  type={'password'}
                  label={t('Senha*')}
                  size="small"
                  value={registration.password}
                  onChange={(e) => setRegistration({ ...registration, password: e.target.value })}
                  error={!!registrationError.password}
                  helperText={t(registrationError.password)}
                  onClick={() => setRegistrationError({ ...registrationError, password: '' })}
                  sx={{
                    marginTop: 2,
                  }}
                  fullWidth
                  style={{ marginTop: '24px' }}
                />
              )}
              {!token && (
                <S.TextPassword>
                  {t(
                    'Para uma maior segurança, a senha deve ter ao menos 8 caracteres, letra maiúscula, números e símbolos',
                  )}
                </S.TextPassword>
              )}
            </S.ContainerInput>

            <div style={{ margin: '24px 0', display: 'flex', gap: '10px' }}>
              <Autocomplete
                size="small"
                id="available-companies"
                options={availableCompaniesToLink.filter(
                  (option) => !linkedCompaniesList.some((c) => c.id === option.id),
                )}
                getOptionLabel={(option) => option.fantasyName}
                value={selectedCompanyToLink}
                onChange={(_, newValue) => {
                  setSelectedCompanyToLink(newValue)
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t('Empresas Disponíveis')}
                    variant="outlined"
                    error={!!registrationError.companyId}
                    helperText={registrationError.companyId}
                  />
                )}
                clearOnEscape
                sx={{ width: '100%' }}
              />
              <Button
                type="button"
                label={t('Vincular')}
                style={{ width: '200px', marginTop: 0 }}
                onClick={() => {
                  if (selectedCompanyToLink) {
                    setRegistration({
                      ...registration,
                      companyIds: [...registration.companyIds, selectedCompanyToLink.id],
                    })
                    setSelectedCompanyToLink(null)
                  }
                }}
              />
            </div>

            {linkedCompaniesList.length > 0 && (
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="collapsible table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        sx={{ fontWeight: 'bold', backgroundColor: '#f5f5f5', width: '50px' }}
                      />
                      <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>
                        Empresa
                      </TableCell>
                      <TableCell
                        align="right"
                        sx={{ fontWeight: 'bold', backgroundColor: '#f5f5f5', width: '100px' }}
                      >
                        Principal?
                      </TableCell>
                      <TableCell
                        align="right"
                        sx={{ fontWeight: 'bold', backgroundColor: '#f5f5f5', width: '50px' }}
                      >
                        Ações
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {linkedCompaniesList.map((company) => (
                      <Fragment key={company.id}>
                        <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                          <TableCell>
                            <IconButton
                              aria-label="expand row"
                              size="small"
                              onClick={() => {
                                setOpenRow(openRow === company.id ? null : company.id)
                              }}
                              disabled={company.linkedSites.length === 0}
                            >
                              {openRow === company.id ? (
                                <KeyboardArrowUpIcon />
                              ) : (
                                <KeyboardArrowDownIcon />
                              )}
                            </IconButton>
                          </TableCell>
                          <TableCell>{company.fantasyName}</TableCell>
                          <TableCell align="right">
                            <Radio
                              checked={company.id === registration.companyId}
                              onClick={() => setRegistration({ ...registration, companyId: company.id })}
                            />
                          </TableCell>
                          <TableCell align="right">
                            <Tooltip title={t('Deletar')}>
                              <IconButton
                                disabled={company.id === registration.companyId}
                                onClick={() => {
                                  setLinkedCompaniesList(
                                    linkedCompaniesList.filter((c) => c.id !== company.id),
                                  )
                                }}
                                sx={{ color: 'red' }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={4}>
                            <Collapse in={openRow === company.id} timeout="auto" unmountOnExit>
                              <Box sx={{ marginLeft: 12.5, marginRight: 1 }}>
                                <Table size="small" aria-label="sites">
                                  <TableHead sx={{ backgroundColor: '#f5f5f5' }}>
                                    <TableRow>
                                      <TableCell sx={{ fontWeight: 'bold' }}>Sítio</TableCell>
                                      <TableCell
                                        sx={{ fontWeight: 'bold', width: '50px' }}
                                        align="right"
                                      >
                                        Vinculado?
                                      </TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {company.linkedSites.map((site: any) => (
                                      <TableRow key={site.id}>
                                        <TableCell>{site.siteName}</TableCell>
                                        <TableCell align="center">
                                          <Tooltip
                                            title={
                                              registration.accessLevel === AccessLevel.Admin ||
                                              registration.accessLevel === AccessLevel.Master
                                                ? t('Sempre habilitado caso Admin/Master')
                                                : ''
                                            }
                                          >
                                            <span>
                                              <Checkbox
                                                checked={
                                                  registration.accessLevel === AccessLevel.Admin ||
                                                  registration.accessLevel === AccessLevel.Master
                                                    ? true
                                                    : site.allowed
                                                }
                                                onChange={() =>
                                                  handleCheckboxChange(site.companyId, site.siteId)
                                                }
                                                disabled={
                                                  registration.accessLevel === AccessLevel.Admin ||
                                                  registration.accessLevel === AccessLevel.Master
                                                }
                                              />
                                            </span>
                                          </Tooltip>
                                        </TableCell>
                                      </TableRow>
                                    ))}
                                  </TableBody>
                                </Table>
                              </Box>
                            </Collapse>
                          </TableCell>
                        </TableRow>
                      </Fragment>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
            <S.ContainerButton>
              <Button
                type="button"
                onClick={() => setOpenConfirmationModal(true)}
                label={t('Cancelar')}
                style={{ marginTop: 0 }}
              />
              <Button
                type="button"
                onClick={RegisterAndEditUser}
                loading={loading}
                label={t('Salvar')}
                style={{ marginTop: 0, marginLeft: '40px' }}
              />
            </S.ContainerButton>
          </S.ContainerForm>
        ) : (
          <ViewError message={t('Não foi possível carregar os dados, tente novamente mais tarde.')} />
        )}
        <ModalCustom
          open={openConfirmationModal}
          onClose={() => setOpenConfirmationModal(false)}
          onClick={() => {
            setOpenConfirmationModal(false)
            navigate(-1)
          }}
          title={token ? t('Cancelar edição') : t('Cancelar cadastro')}
          description={
            token
              ? t(
                  'Tem certeza de que deseja cancelar a edição? Após a confirmação, não há como voltar atrás.',
                )
              : t(
                  'Tem certeza de que deseja cancelar o cadastro? Após a confirmação, não há como voltar atrás.',
                )
          }
          confirmationButtonText={t('Sim')}
          textDeclineButton={t('Não')}
        />
      </S.FlexWrapper>
    </BaseLayout>
  )
}
