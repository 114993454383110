import MapIcon from '@mui/icons-material/Map'
import { useState } from 'react'
import {
  Menu,
  MenuItem,
  ProSidebar,
  SidebarContent,
  SidebarFooter,
  SidebarHeader,
} from 'react-pro-sidebar'
import 'react-pro-sidebar/dist/css/styles.css'

import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { useTheme } from 'styled-components'
import Icon from '~/assets/icons'
import { useAuth } from '~/contexts/Auth'

import { Tooltip } from '@mui/material'
import { AccessLevel } from '~/utils/General'
import * as S from './styles'

const Sidebar = () => {
  const [menuCollapse, setMenuCollapse] = useState(true)
  const theme = useTheme()
  const { signOut, user } = useAuth()
  const { t } = useTranslation()

  const menuIconClick = () => {
    menuCollapse ? setMenuCollapse(false) : setMenuCollapse(true)
  }

  const masterAccess = user?.accessLevel === AccessLevel.Master
  const adminAccess = user?.accessLevel === AccessLevel.Admin

  const tooltipModifiers = [
    {
      name: 'offset',
      options: {
        offset: [-70, 0],
      },
    },
  ]

  return (
    <S.Container>
      <div id="header">
        <ProSidebar collapsed={menuCollapse}>
          <SidebarHeader>
            <S.CloseMenuButton onClick={menuIconClick} aria-label="Toggle Menu">
              {menuCollapse ? (
                <Icon name="ArrowForward" fill={theme.colors.white} />
              ) : (
                <Icon name="ArrowBack" fill={theme.colors.white} />
              )}
            </S.CloseMenuButton>
          </SidebarHeader>
          <SidebarContent>
            <Menu iconShape="square">
              <MenuItem icon={<Icon name="Home" fill="none" />}>
                {menuCollapse && (
                  <Tooltip
                    title={t('Início (Dashboard)')}
                    placement="bottom"
                    PopperProps={{ modifiers: tooltipModifiers }}
                  >
                    <Link to="/">{t('Início (Dashboard)')}</Link>
                  </Tooltip>
                )}
                {!menuCollapse && <Link to="/">{t('Início (Dashboard)')}</Link>}
              </MenuItem>

              {(masterAccess || adminAccess) && (
                <>
                  <MenuItem active={true} icon={<Icon name="Person" fill="none" />}>
                    {!menuCollapse ? (
                      <Link to="/usuarios">{t('Usuários')}</Link>
                    ) : (
                      <Tooltip
                        title={t('Usuários')}
                        placement="bottom"
                        PopperProps={{ modifiers: tooltipModifiers }}
                      >
                        <Link to="/usuarios">{t('Usuários')}</Link>
                      </Tooltip>
                    )}
                  </MenuItem>

                  <MenuItem active={true} icon={<Icon name="Business" fill="white" />}>
                    {menuCollapse ? (
                      <Tooltip
                        title={t('Empresas')}
                        placement="bottom"
                        PopperProps={{ modifiers: tooltipModifiers }}
                      >
                        <Link to="/empresas-e-filiais">{t('Empresas')}</Link>
                      </Tooltip>
                    ) : (
                      <Link to="/empresas-e-filiais">{t('Empresas')}</Link>
                    )}
                  </MenuItem>

                  <MenuItem active={true} icon={<Icon name="Registration" fill="white" />}>
                    {menuCollapse ? (
                      <Tooltip
                        title={t('Inspetoras')}
                        placement="bottom"
                        PopperProps={{ modifiers: tooltipModifiers }}
                      >
                        <Link to="/empresas-inspetoras">{t('Inspetoras')}</Link>
                      </Tooltip>
                    ) : (
                      <Link to="/empresas-inspetoras">{t('Inspetoras')}</Link>
                    )}
                  </MenuItem>
                </>
              )}

              <MenuItem
                active={true}
                icon={<Icon name="SolarPanel" fill="none" height={20} width={20} />}
              >
                {menuCollapse ? (
                  <Tooltip
                    title={t('Parques solares')}
                    placement="bottom"
                    PopperProps={{ modifiers: tooltipModifiers }}
                  >
                    <Link to="/sites">{t('Parques solares')}</Link>
                  </Tooltip>
                ) : (
                  <Link to="/sites">{t('Parques solares')}</Link>
                )}
              </MenuItem>

              <MenuItem active={true} icon={<Icon name="Description" fill="white" />}>
                {menuCollapse ? (
                  <Tooltip
                    title={t('Inspeções')}
                    placement="bottom"
                    PopperProps={{ modifiers: tooltipModifiers }}
                  >
                    <Link to="/sites/inspections">{t('Inspeções')}</Link>
                  </Tooltip>
                ) : (
                  <Link to="/sites/inspections">{t('Inspeções')}</Link>
                )}
              </MenuItem>

              <MenuItem active={true} icon={<MapIcon sx={{ color: 'white' }} />}>
                {menuCollapse ? (
                  <Tooltip
                    title={t('Mapa')}
                    placement="bottom"
                    PopperProps={{ modifiers: tooltipModifiers }}
                  >
                    <Link to="/mapa/">{t('Mapa')}</Link>
                  </Tooltip>
                ) : (
                  <Link to="/mapa/">{t('Mapa')}</Link>
                )}
              </MenuItem>

              <MenuItem active={true} icon={<Icon name="Graphic" fill="none" />}>
                {menuCollapse ? (
                  <Tooltip
                    title={t('Relatórios')}
                    placement="bottom"
                    PopperProps={{ modifiers: tooltipModifiers }}
                  >
                    <Link to="/relatorios">{t('Relatórios')}</Link>
                  </Tooltip>
                ) : (
                  <Link to="/relatorios">{t('Relatórios')}</Link>
                )}
              </MenuItem>

              {(masterAccess || adminAccess) && (
                <MenuItem active={true} icon={<Icon name="SettingsIcon" fill="none" />}>
                  {menuCollapse ? (
                    <Tooltip
                      title={t('Configurações')}
                      placement="bottom"
                      PopperProps={{ modifiers: tooltipModifiers }}
                    >
                      <Link to="/settings">{t('Configurações')}</Link>
                    </Tooltip>
                  ) : (
                    <Link to="/settings">{t('Configurações')}</Link>
                  )}
                </MenuItem>
              )}

              {(masterAccess || adminAccess) && (
                <MenuItem active={true} icon={<Icon name="Repair" fill="white" />}>
                  {menuCollapse ? (
                    <Tooltip
                      title={t('Equipamentos')}
                      placement="bottom"
                      PopperProps={{ modifiers: tooltipModifiers }}
                    >
                      <Link to="/hardware">{t('Equipamentos')}</Link>
                    </Tooltip>
                  ) : (
                    <Link to="/hardware">{t('Equipamentos')}</Link>
                  )}
                </MenuItem>
              )}

              {masterAccess && (
                <MenuItem active={true} icon={<Icon name="Description" fill="#fff" />}>
                  {menuCollapse ? (
                    <Tooltip
                      title={t('Logs do Sistema')}
                      placement="bottom"
                      PopperProps={{ modifiers: tooltipModifiers }}
                    >
                      <Link to="/logs-do-sistema">{t('Logs do Sistema')}</Link>
                    </Tooltip>
                  ) : (
                    <Link to="/logs-do-sistema">{t('Logs do Sistema')}</Link>
                  )}
                </MenuItem>
              )}
            </Menu>
          </SidebarContent>
          <SidebarFooter>
            <Menu className="menuLeft" iconShape="square">
              <MenuItem active={true} onClick={signOut} icon={<Icon name="Loggout" fill="#0C4A7D" />}>
                {menuCollapse ? (
                  <Tooltip
                    title={t('Sair')}
                    placement="bottom"
                    PopperProps={{ modifiers: tooltipModifiers }}
                  >
                    <Link to="/login">{t('Sair')}</Link>
                  </Tooltip>
                ) : (
                  <Link to="/login">{t('Sair')}</Link>
                )}
              </MenuItem>
            </Menu>
          </SidebarFooter>
        </ProSidebar>
      </div>
    </S.Container>
  )
}

export default Sidebar
