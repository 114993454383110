import React, { useEffect, useState } from 'react'

import { Autocomplete, Button, Stack, TextField, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { getInspectorCompanyById } from '~/services/InspectorCompany'
import { IInspectorCompanyResponse } from '~/services/InspectorCompany/types'
import { EtapaProps, ICompanyRemoved } from '../types'
import { validateEtapa1Data } from '../validations'

const Etapa1View: React.FC<
  EtapaProps & {
    etapa1Data: {
      selectedDate: string
      inspectorCompanyId: string
      companyContact: string
      termografista: string
      auxiliar: string
      uas: string
      payload: string
    }
    companies: IInspectorCompanyResponse[]
    empresaInexistente?: ICompanyRemoved
    selectCompany: string | null
    onFieldChange: (fieldName: string, value: string) => void
    setSelectedCompanyId: (id: string | null) => void
  }
> = ({ handleNext, etapa1Data, onFieldChange, companies }) => {
  const [errors, setErrors] = useState<{ [key: string]: string }>({})
  const [selectedInspectorCompany, setSelectedInspectorCompany] = useState<any>(null)
  const { t } = useTranslation()

  const handleValidationAndNext = async () => {
    const result = await validateEtapa1Data(etapa1Data, t)
    if (result === true) {
      handleNext()
    } else {
      setErrors(result)
    }
  }

  const navigate = useNavigate()

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target
    if (name === 'selectedDate') {
      if (value) {
        const date = new Date(value)
        if (!isNaN(date.getTime())) {
          const isoDate = date.toISOString()
          onFieldChange(name, isoDate)
        } else {
          onFieldChange(name, '')
        }
      } else {
        onFieldChange(name, '')
      }
    } else {
      onFieldChange(name, value)
    }
  }

  const handleSelectedInspectorCompany = async (inspectorCompanyId: string) => {
    const response = await getInspectorCompanyById({
      id: inspectorCompanyId,
      withLogoIfExists: false,
    })
    if (response.success) {
      const inspectorCompany = response.data
      if (inspectorCompany) {
        onFieldChange('companyContact', inspectorCompany.email ?? '')
        onFieldChange('termografista', inspectorCompany.thermographer ?? '')
        onFieldChange('auxiliar', inspectorCompany.assistants ?? '')
      }
    }
  }

  useEffect(() => {
    if (etapa1Data.inspectorCompanyId && companies) {
      setSelectedInspectorCompany(
        companies.find((company) => company?.id?.toString() === etapa1Data.inspectorCompanyId),
      )
    }
  }, [etapa1Data.inspectorCompanyId, companies])

  return (
    <Stack
      sx={{
        margin: '24px 10%',
        display: 'flex',
        flexDirection: 'column',
        gap: '30px',
        backgroundColor: 'white',
        padding: '20px',
        borderRadius: '16px',
        border: '1px solid #C4C4C4',
      }}
    >
      <Stack direction="column" gap={2} sx={{ width: '100%' }}>
        <Typography variant="h6" fontWeight="bold">
          {t('Data da Inspeção')}
        </Typography>

        <TextField
          type="date"
          name="selectedDate"
          value={
            etapa1Data.selectedDate
              ? new Date(etapa1Data.selectedDate).toISOString().substring(0, 10)
              : ''
          }
          onChange={handleInputChange}
          label={t('Data')}
          variant="outlined"
          size="small"
          style={{ width: '300px' }}
          id="input-date"
          required
          InputLabelProps={{
            shrink: true,
          }}
          error={Boolean(errors['selectedDate'])}
          helperText={errors['selectedDate'] || ''}
        />
      </Stack>
      <Stack direction="column" gap={2} sx={{ width: '100%' }}>
        <Typography variant="h6" fontWeight="700">
          {t('Empresa')}
        </Typography>
        <Stack direction="row" gap={2} sx={{ width: '100%' }}>
          <Autocomplete
            options={companies}
            getOptionLabel={(option) => option.name ?? ''}
            value={selectedInspectorCompany}
            onChange={(event, newValue) => {
              if (newValue) {
                const newInspectorCompanyId = newValue.id ? newValue.id.toString() : ''
                setSelectedInspectorCompany(newValue)
                onFieldChange('inspectorCompanyId', newInspectorCompanyId)
                handleSelectedInspectorCompany(newInspectorCompanyId)
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t('Empresa/Equipe Inspetora')}
                variant="outlined"
                size="small"
                required
                error={Boolean(errors['inspectorCompanyId'])}
                helperText={errors['inspectorCompanyId'] || ''}
              />
            )}
            clearOnEscape={false}
            clearIcon={null}
            sx={{ width: '50%' }}
          />

          <TextField
            type="text"
            name="companyContact"
            value={etapa1Data.companyContact}
            onChange={handleInputChange}
            variant="outlined"
            size="small"
            sx={{ width: '50%' }}
            id="input-contato"
            label={t('Email')}
            error={Boolean(errors['companyContact'])}
            helperText={errors['companyContact'] || ''}
          />
        </Stack>
      </Stack>
      <Stack direction="column" gap={2}>
        <Typography variant="h6" fontWeight="700">
          {t('Inspetores')}
        </Typography>

        <Stack direction="row" gap={2} sx={{ width: '100%' }}>
          <TextField
            type="text"
            value={etapa1Data.termografista ?? ''}
            variant="outlined"
            name="termografista"
            onChange={handleInputChange}
            size="small"
            sx={{ width: '50%' }}
            id="input-termografista"
            label={t('Termografista')}
            error={Boolean(errors['termografista'])}
            helperText={errors['termografista'] || ''}
          />

          <TextField
            type="text"
            name="auxiliar"
            value={etapa1Data.auxiliar}
            onChange={handleInputChange}
            variant="outlined"
            size="small"
            sx={{ width: '50%' }}
            id="input-auxiliar"
            label={t('Auxiliar')}
            error={Boolean(errors['auxiliar'])}
            helperText={errors['auxiliar'] || ''}
          />
        </Stack>
      </Stack>
      <Stack direction="column" gap={2}>
        <Typography variant="h6" fontWeight="700">
          {t('Equipamentos')}
        </Typography>

        <Stack direction="row" gap={2} sx={{ width: '100%' }}>
          <Autocomplete
            id="autocomplete-uas"
            disablePortal
            size="small"
            options={['M3T', 'M30T', 'Matrice 200', 'Matrice 300']}
            sx={{ width: '50%' }}
            value={etapa1Data.uas}
            onChange={(event, newValue) => onFieldChange('uas', newValue ?? '')}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t('UAS')}
                error={Boolean(errors['uas'])}
                helperText={errors['uas'] || ''}
              />
            )}
          />

          <Autocomplete
            id="autocomplete-payload"
            disablePortal
            size="small"
            options={['M3T', 'M30T', 'H20T', 'XT2']}
            sx={{ width: '50%' }}
            value={etapa1Data.payload}
            onChange={(event, newValue) => onFieldChange('payload', newValue ?? '')}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t('Payload')}
                error={Boolean(errors['payload'])}
                helperText={errors['payload'] || ''}
              />
            )}
          />
        </Stack>
      </Stack>

      <Stack
        width={'100%'}
        gap={2}
        py={2}
        flexDirection="row"
        alignItems="flex-end"
        justifyContent="space-between"
      >
        <Button variant="outlined" onClick={() => navigate(-1)}>
          {t('Voltar')}
        </Button>

        <Button
          variant="contained"
          onClick={handleValidationAndNext}
          sx={{
            backgroundColor: '#0C4A7D',
            color: 'white',
            '&:hover': {
              backgroundColor: '#084a5d',
            },
          }}
        >
          {t('Avançar')}
        </Button>
      </Stack>
    </Stack>
  )
}

export default Etapa1View
