import { Autocomplete, FormControl, TableCell, TablePagination, TextField } from '@mui/material'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import BaseLayout from '~/Layouts/BaseLayout'
import { PAGE_SIZE } from '~/assets/Constants'
import Icon from '~/assets/icons'
import { MaterialIcon } from '~/assets/icons/materialIcons'
import Button from '~/components/Button'
import { ButtonLink } from '~/components/ButtonLink'
import InputSearch from '~/components/InputSearch'
import MaterialTable from '~/components/Material/Table'
import ModalCustom from '~/components/ModalCustom'
import ToastNotification from '~/components/ToastNotification'
import ViewError from '~/components/ViewError'
import ViewLoading from '~/components/ViewLoading'
import { useAuth } from '~/contexts/Auth'
import { deleteUser, getAcessLevel, getUsers } from '~/services/Users'
import { IUserAcessLevel } from '~/services/Users/types'
import { renderStatus, validateIfNull } from '~/utils/Mask'
import ErrorToast from '~/utils/toastErrorCatch'
import * as S from './styles'
import { IFilter, IUsersList } from './types'
import { emptyItem } from './utils'
import { ICompaniesSimple } from '../CompaniesAndBranches/types'
import { getCompanySimple } from '~/services/CompaniesAndBranches'

export default function Users() {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { user } = useAuth()

  const [loading, setLoading] = useState(false)
  const [usersList, setUsersList] = useState<IUsersList[]>([])
  const [filters, setFilters] = useState<IFilter>(new IFilter())
  const [currentPage, setCurrentPage] = useState(0)
  const [totalItens, setTotalItens] = useState<number | undefined>(0)
  const [itemSelected, setItemSelected] = useState<IUsersList>(emptyItem)
  const [openDeleteUserModal, setOpenDeleteUserModal] = useState(false)
  const [accessLevels, setAccessLevels] = useState<IUserAcessLevel[]>([])
  const [companies, setCompanies] = useState<ICompaniesSimple[]>([])

  const companiesOptions = companies.map((c) => {
    return {
      id: c.id,
      name: c.fantasyName || c.corporateName,
    }
  })

  useEffect(() => {
    loadUsers(1, undefined, true)
  }, [])

  const statusOptions = [
    { id: '1', name: t('Ativo') },
    { id: '2', name: t('Inativo') },
  ]

  const onEnterDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      loadUsers(1, filters)
    }
  }

  const loadUsers = async (page: number, filter?: IFilter, clear: boolean = false) => {
    setLoading(true)
    try {
      if (clear) {
        setUsersList([])
      }
      const paginationAddresses = {
        page,
        pageSize: PAGE_SIZE,
        search: filter?.search,
        accessLevel:
          filter?.accessLevel !== undefined && filter?.accessLevel !== null ? filter?.accessLevel : '',
        statusUser: filter?.statusUser !== '' ? filter?.statusUser : '',
        companyId: filter?.companyId !== '' ? filter?.companyId : '',
      }

      const response = await getUsers(paginationAddresses)
      if (response.success) {
        setTotalItens(response?.itemsTotal)
        setCurrentPage(page)
        setUsersList(response.data)
      } else {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Não foi possível carregar os dados, tente novamente mais tarde.'),
          errorMessage: response.errorDetails,
          errors: response.errors,
        })
      }
    } catch (error) {
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Não foi possível carregar os dados, tente novamente mais tarde.'),
      })
    }
    setLoading(false)
  }

  useEffect(() => {
    const fetchAccessLevels = async () => {
      try {
        const levels = await getAcessLevel()
        setAccessLevels(levels.data)
      } catch (error) {
        console.error(error)
      }
    }

    fetchAccessLevels()
  }, [])

  const headCells: any = [
    {
      label: t('Usuário'),
      value: 'user',
      alignment: 'left',
    },
    {
      label: t('E-mail'),
      value: 'email',
    },
    {
      label: t('Empresa'),
      value: 'company',
    },
    {
      label: t('Status'),
      value: 'status',
    },
    {
      label: t('Nível de acesso'),
      value: 'accessLevel',
    },
    {
      label: t('Ações'),
      value: '',
    },
  ]

  const getAccessLevelName = (accessLevelId: number) => {
    const level = accessLevels.find((level) => Number(level.id) === Number(accessLevelId))
    return level ? t(`AccessLevels.${level.name}`) : '-'
  }

  const handleDeleteUser = async () => {
    setLoading(true)
    try {
      if (itemSelected?.id) {
        const response = await deleteUser(itemSelected.id)
        if (response.success) {
          ToastNotification({
            id: 'success',
            type: 'success',
            message: t('Usuário removido com sucesso!'),
          })
          loadUsers(1, undefined, true)
          setOpenDeleteUserModal(false)
        } else {
          ToastNotification({
            id: 'error',
            type: 'error',
            message: t(response.message),
            errorMessage: response.errorDetails,
            errors: response.errors,
          })
        }
      }
    } catch (error) {
      return (
        <ErrorToast message={t('Não foi possível excluir o usuário, tente novamente mais tarde.')} />
      )
    }

    setLoading(false)
  }

  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        const companies = await getCompanySimple()
        setCompanies(companies.data)
      } catch (error) {
        console.error(error)
      }
    }

    fetchCompanies()
  }, [])
  return (
    <BaseLayout
      title={t('Usuários')}
      extraContent={
        <Button
          iconStart={<Icon name="Add" fill="white" />}
          type="button"
          onClick={() => navigate(`/cadastro-de-usuarios`)}
          label={t('Cadastrar Usuário')}
          style={{ marginTop: 0, marginLeft: '24px', width: '230px' }}
        />
      }
    >
      <S.FlexWrapper>
        <S.ContainerFilter style={{ gap: '8px' }}>
          <S.ContainerSearch>
            <InputSearch
              placeholder={t('Pesquise pelo nome do usuário') ?? 'Pesquise pelo nome do usuário'}
              value={filters.search}
              onChange={(e) => setFilters({ ...filters, search: e.target.value })}
              onKeyDown={onEnterDown}
              variant="outlined"
              fullWidth
            />
          </S.ContainerSearch>
          <FormControl style={{ display: 'flex', width: '60%' }}>
            <Autocomplete
              id="autocomplete-companies"
              options={companiesOptions}
              getOptionLabel={(option) => option.name}
              value={companiesOptions.find((option) => option.id === filters.companyId) ?? null}
              onChange={(_, newValue) => {
                setFilters({ ...filters, companyId: newValue ? newValue.id : '' })
              }}
              renderInput={(params) => (
                <TextField {...params} label={t('Empresas')} variant="outlined" size="small" />
              )}
            />
          </FormControl>
          <FormControl style={{ display: 'flex', width: '30%' }}>
            <Autocomplete
              id="autocomplete-status"
              options={statusOptions}
              getOptionLabel={(option) => option.name}
              value={statusOptions.find((option) => option.id === filters.statusUser) ?? null}
              onChange={(_, newValue) => {
                setFilters({ ...filters, statusUser: newValue ? newValue.id : '' })
              }}
              renderInput={(params) => (
                <TextField {...params} label={t('Status')} variant="outlined" size="small" />
              )}
            />
          </FormControl>
          <FormControl style={{ display: 'flex', width: '30%', gap: 2 }}>
            {accessLevels && (
              <Autocomplete
                id="autocomplete-acessLevel"
                options={accessLevels}
                getOptionLabel={(option) => t(`AccessLevels.${option.name}`)}
                value={accessLevels.find((option) => option.id === filters.accessLevel) ?? null}
                onChange={(_, newValue) => {
                  setFilters({ ...filters, accessLevel: newValue ? newValue.id : '' })
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t('Nível de acesso')}
                    variant="outlined"
                    size="small"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: <>{params.InputProps.endAdornment}</>,
                    }}
                  />
                )}
              />
            )}
          </FormControl>

          <S.ContainerButton>
            <Button
              type="button"
              onClick={() => loadUsers(1, filters)}
              label={t('Pesquisar')}
              style={{ marginTop: 0 }}
            />
          </S.ContainerButton>
        </S.ContainerFilter>

        {loading ? (
          <ViewLoading />
        ) : (
          <S.Main>
            {usersList.length > 0 ? (
              <MaterialTable<IUsersList>
                headCells={headCells}
                page={0}
                tableValues={usersList.map((item) => ({
                  id: item?.id,
                  name: item?.name,
                  email: item?.email,
                  company: item?.company,
                  status: item?.status,
                  accessLevel: item?.accessLevel,
                }))}
                renderRow={(item) => {
                  return (
                    <>
                      <TableCell component="th" scope="row">
                        {validateIfNull(item?.name)}
                      </TableCell>
                      <TableCell align="right">{validateIfNull(item?.email)}</TableCell>
                      <TableCell align="right">{validateIfNull(item?.company)}</TableCell>
                      <TableCell align="right">{t(renderStatus(item?.status ?? 0))}</TableCell>
                      <TableCell align="right">{getAccessLevelName(item?.accessLevel ?? 0)}</TableCell>
                      <S.TableCell align="right">
                        <S.Icons>
                          <ButtonLink
                            route={'/edicao-de-usuarios/' + item.id}
                            icon={<MaterialIcon name="Edit" title={t('Editar')} />}
                          />
                          {user?.id != item.id && (
                            <button
                              onClick={() => {
                                setItemSelected(item)
                                setOpenDeleteUserModal(true)
                              }}
                            >
                              <MaterialIcon
                                title={t('Remover')}
                                name="Delete"
                                style={{ marginLeft: '24px' }}
                              />
                            </button>
                          )}
                        </S.Icons>
                      </S.TableCell>
                    </>
                  )
                }}
                pagination={() => (
                  <TablePagination
                    rowsPerPageOptions={[8]}
                    rowsPerPage={8}
                    component="div"
                    count={totalItens ?? 0}
                    page={currentPage - 1}
                    onPageChange={(e, p) => {
                      e?.preventDefault()
                      e?.stopPropagation()
                      loadUsers(p + 1, filters)
                    }}
                  />
                )}
              />
            ) : (
              <ViewError message={t('Nenhum usuário encontrado')} />
            )}
          </S.Main>
        )}
        <ModalCustom
          open={openDeleteUserModal}
          onClose={() => setOpenDeleteUserModal(false)}
          onClick={() => {
            handleDeleteUser()
          }}
          loading={loading}
          title={t('Excluir Usuário')}
          description={t(
            'Tem certeza de que deseja excluir o usuário? Após a confirmação, não há como voltar atrás.',
          )}
          confirmationButtonText={t('Excluir')}
          textDeclineButton={t('Cancelar')}
        />
      </S.FlexWrapper>
    </BaseLayout>
  )
}
