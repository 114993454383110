import { TableCell, TablePagination } from '@mui/material'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import Button from '~/components/Button'
import InputSearch from '~/components/InputSearch'
import ViewError from '~/components/ViewError'
import ViewLoading from '~/components/ViewLoading'

import { MaterialIcon } from '~/assets/icons/materialIcons'
import ButtonRemover from '~/components/ButtonDelete'
import { ButtonLink } from '~/components/ButtonLink'
import CustomButton from '~/components/CustomButton'
import MaterialTable from '~/components/Material/Table'
import { HeadCell } from '~/components/Material/Table/types'
import ModalCustom from '~/components/ModalCustom'
import ToastNotification from '~/components/ToastNotification'
import { useAuth } from '~/contexts/Auth'
import BaseLayout from '~/Layouts/BaseLayout'
import { deleteInspectorCompanyById, getInspectorCompanySimple } from '~/services/InspectorCompany'
import { IInspectorCompanyResponse } from '~/services/InspectorCompany/types'
import { AccessLevel } from '~/utils/General'
import * as S from '../CompaniesAndBranches/styles'
import * as G from '../styles'

export default function InspectorCompanies() {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { user } = useAuth()
  const [loading, setLoading] = useState(false)
  const [companiesList, setCompaniesList] = useState<IInspectorCompanyResponse[]>([])
  const [companyNameFilter, setCompanyNameFilter] = useState('')
  const [openDeleteCompanyModal, setOpenDeleteCompanyModal] = useState<number | null>(null)
  const [currentPage, setCurrentPage] = useState(0)
  const [totalItens, setTotalItens] = useState<number | undefined>(0)

  const loadInspectorCompanies = async () => {
    setLoading(true)
    try {
      const response = await getInspectorCompanySimple({
        filter: companyNameFilter,
        pageNumber: currentPage + 1,
        pageSize: 10,
        onlyOwned: true,
      })
      if (response.success) {
        setCompaniesList(response.data)
        setTotalItens(response.itemsTotal)
      } else {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Não foi possível carregar os dados, tente novamente mais tarde.'),
          errorMessage: response.errorDetails,
          errors: response.errors,
        })
      }
    } catch (error) {
      console.error(error)
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Não foi possível carregar os dados, tente novamente mais tarde.'),
      })
    }
    setLoading(false)
  }

  const headCells: HeadCell[] = [
    {
      label: t('Empresa'),
      value: 'name',
      alignment: 'left',
    },
    {
      label: t('Email'),
      value: 'email',
    },
    {
      label: t('Ações'),
      value: '',
    },
  ]

  useEffect(() => {
    loadInspectorCompanies()
  }, [currentPage])

  const handleEditClick = (inspectorCompanyId: number) => {
    navigate(`/edicao-de-empresas-inspetoras/${inspectorCompanyId}`, {
      state: {
        edit: true,
      },
    })
  }

  const handleDeleteInspectorCompany = async (inspectorCompanyId: number) => {
    try {
      const response = await deleteInspectorCompanyById({ id: inspectorCompanyId.toString() })
      if (response.success) {
        ToastNotification({
          id: 'success',
          type: 'success',
          message: t('Os dados foram excluídos com sucesso!'),
        })
        setOpenDeleteCompanyModal(null)
        loadInspectorCompanies()
      }
    } catch (error) {
      console.error(error)
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Não foi possível deletar os dados, tente novamente mais tarde.'),
        errorMessage: error as any,
        errors: error as any,
      })
    }
  }

  return (
    <BaseLayout
      title={t('Inspetoras')}
      extraContent={
        (user?.accessLevel === AccessLevel.Master || user?.accessLevel === AccessLevel.Admin) && (
          <CustomButton
            label={t('Cadastrar Inspetora')}
            onClick={() => navigate(`/cadastro-de-empresas-inspetoras`)}
          />
        )
      }
    >
      <S.FlexWrapper>
        <G.ContainerFilter>
          <S.ContainerFilter>
            <S.ContainerSearch>
              <InputSearch
                placeholder={t('Pesquise por nome da empresa') ?? 'Pesquise por nome da empresa'}
                value={companyNameFilter}
                onChange={(event) => setCompanyNameFilter(event.target.value)}
                onKeyDown={(event) => {
                  if (event.key === 'Enter') {
                    loadInspectorCompanies()
                  }
                }}
                variant="outlined"
                fullWidth
              />
            </S.ContainerSearch>
            <G.ContainerButton>
              <Button
                type="button"
                onClick={() => loadInspectorCompanies()}
                label={t('Pesquisar')}
                style={{ marginTop: 0 }}
              />
            </G.ContainerButton>
          </S.ContainerFilter>
        </G.ContainerFilter>

        {loading ? (
          <ViewLoading />
        ) : (
          <G.Main>
            {companiesList.length > 0 ? (
              <MaterialTable<IInspectorCompanyResponse>
                headCells={headCells}
                page={currentPage - 1}
                tableValues={companiesList.map((item) => ({
                  id: item?.id,
                  name: item?.name,
                  email: item?.email,
                }))}
                renderRow={(item) => {
                  return (
                    <>
                      <TableCell component="th" scope="row">
                        {item?.name}
                      </TableCell>
                      <TableCell align="right">{item?.email}</TableCell>
                      <TableCell align="right">
                        <S.Icons>
                          <ButtonLink
                            route=""
                            onClick={() => handleEditClick(item?.id ?? 0)}
                            icon={<MaterialIcon name="Edit" title={t('Editar')} />}
                          />
                          {user?.accessLevel === 1 && (
                            <ButtonRemover<IInspectorCompanyResponse>
                              item={item}
                              setItemSelected={() => {}}
                              setOpenDeleteUserModal={() => setOpenDeleteCompanyModal(item?.id ?? 0)}
                              t={t}
                            />
                          )}
                        </S.Icons>
                      </TableCell>
                    </>
                  )
                }}
                pagination={() => (
                  <TablePagination
                    component="div"
                    rowsPerPageOptions={[10]}
                    rowsPerPage={10}
                    count={totalItens ?? 0}
                    page={currentPage}
                    onPageChange={(event, page) => setCurrentPage(page)}
                  />
                )}
              />
            ) : (
              <ViewError message={t('Nenhuma empresa encontrada')} />
            )}
          </G.Main>
        )}
      </S.FlexWrapper>

      <ModalCustom
        open={!!openDeleteCompanyModal}
        onClose={() => setOpenDeleteCompanyModal(null)}
        onClick={() => {
          handleDeleteInspectorCompany(openDeleteCompanyModal ?? 0)
          setOpenDeleteCompanyModal(null)
        }}
        loading={loading}
        title={t('Remover Empresa')}
        description={t(
          'Tem certeza de que deseja remover esta empresa? Após a confirmação, não há como voltar atrás.',
        )}
        confirmationButtonText={t('Excluir')}
        textDeclineButton={t('Cancelar')}
      />
    </BaseLayout>
  )
}
