import {
  Autocomplete,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Slider,
  TextField,
  Typography,
} from '@mui/material'
import React, { CSSProperties, useEffect, useState } from 'react'
import ToastNotification from '~/components/ToastNotification'
import { getSimpleSite } from '~/services/Site'
import * as S from '../Sites/styles'
import { SelectOption } from './types'

import { getInspectionsSimple } from '~/services/Inspections'
import { getReportFilters, postReports } from '~/services/Reports'

import { Box } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import LoadingComponent from '~/components/Loading/Loading'
import { getSiteActivityTimelineCSV } from '~/services/SiteActivity'

interface ReportModalProps {
  open: boolean
  handleClose: () => void
  reportId?: number
  onSuccess?: () => void
}

const listboxProps: CSSProperties = {
  maxHeight: '200px',
  overflowY: 'auto',
}

function ReportModal({ open, handleClose, reportId, onSuccess }: Readonly<ReportModalProps>) {
  const [loading, setLoading] = useState(false)
  const { t } = useTranslation()

  const [siteOptions, setSiteOptions] = useState<SelectOption[]>([])
  const [inspectionOptions, setInspectionOptions] = useState<SelectOption[]>([])
  const [reportsSubparks, setReportsSubparks] = useState<SelectOption[]>([])
  const [anomalyType, setAnomalyType] = useState<SelectOption[]>([])
  const [temperatureRange, setTemperatureRange] = useState(['0', '150'])

  const [selectIspectionId, setSelectIspectionId] = useState('')
  const [selectedSiteId, setSelectedSiteId] = useState('')
  const [selectSubparksIds, setSelectSubparksIds] = useState<number[]>([])
  const [selectAnomalyTypeIds, setSelectAnomalyTypeIds] = useState<number[]>([])

  const [nameReport, setNameReport] = useState('')

  const handleSliderChange = (event: Event, newValue: number | number[]): void => {
    const stringValues = (newValue as number[]).map((value) => value.toString())
    setTemperatureRange(stringValues)
  }

  const handleInputChange =
    (index: number) =>
    (e: React.ChangeEvent<HTMLInputElement>): void => {
      const inputVal = e.target.value.replace(',', '.')
      const newRange = [...temperatureRange]
      newRange[index] = inputVal
      setTemperatureRange(newRange)
    }

  const handleDelete =
    (setter: React.Dispatch<React.SetStateAction<number[]>>) =>
    (id: number, event: React.MouseEvent) => {
      event.preventDefault()
      event.stopPropagation()
      setter((prev) => prev.filter((value) => value !== id))
    }

  const handleBlur = (index: number) => {
    const newRange = [...temperatureRange]
    let inputVal = newRange[index].replace(',', '.')

    let numericValue = parseFloat(inputVal)

    if (isNaN(numericValue) || numericValue < 0) {
      numericValue = 0
    } else if (numericValue > 150) {
      numericValue = 150
    }

    newRange[index] = numericValue.toString()
    setTemperatureRange(newRange)
  }

  const loadSite = async () => {
    try {
      const response = await getSimpleSite()
      if (response.success) {
        const sites = response.data
        const options = sites.map((site) => ({
          label: site.nameAndLocal,
          value: site.id,
        }))
        setSiteOptions(options)
      } else {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Não foi possível carregar os sites, tente novamente mais tarde.'),
          errorMessage: response.errorDetails,
          errors: response.errors,
        })
      }
    } catch (error) {
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Não foi possível carregar os sites, tente novamente mais tarde.'),
      })
    }
  }

  const loadInspections = async () => {
    try {
      const response = await getInspectionsSimple({
        SiteId: selectedSiteId,
        ShowOnlyCompleted: true,
      })
      if (response.success) {
        const sites = response.data
        const options = sites.map((inspection) => ({
          label: inspection.dateAndDescription,
          value: inspection.id,
        }))
        setInspectionOptions(options)
      } else {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Não foi possível carregar os dados, tente novamente mais tarde.'),
          errorMessage: response.errorDetails,
          errors: response.errors,
        })
      }
    } catch (error) {
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Não foi possível carregar os dados, tente novamente mais tarde.'),
      })
    }
  }

  const loadFilters = async () => {
    try {
      const response = await getReportFilters(selectIspectionId)
      if (response.success) {
        const filtersData = response.data
        setReportsSubparks(
          filtersData.subparks.map((sp) => ({
            value: sp.id.toString(),
            label: sp.name,
          })),
        )
        setAnomalyType(
          filtersData.anomalyTypes.map((sp) => ({
            value: sp.id.toString(),
            label: sp.name,
          })),
        )
      } else {
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Não foi possível carregar os dados dos filtros, tente novamente mais tarde.'),
          errorMessage: response.errorDetails,
          errors: response.errors,
        })
      }
    } catch (error) {
      ToastNotification({
        id: 'error',
        type: 'error',
        message: t('Não foi possível carregar os dados dos filtros, tente novamente mais tarde.'),
      })
    }
  }

  const createReports = async () => {
    setLoading(true)

    if (reportId === 4) {
      try {
        const response = await getSiteActivityTimelineCSV({ siteId: selectedSiteId })
        if (response.success) {
          ToastNotification({
            id: 'success',
            type: 'success',
            message: t('Relatório gerado com sucesso!'),
          })
          setSelectedSiteId('')
          setSelectIspectionId('')
          clearFilters()
          handleClose()
          onSuccess?.()
        } else {
          ToastNotification({
            id: 'error',
            type: 'error',
            message: t('Não foi possível carregar os dados, tente novamente mais tarde.'),
            errorMessage: response.errorDetails,
            errors: response.errors,
          })
        }
      } catch (error) {
        console.error(error)
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Não foi possível carregar os dados, tente novamente mais tarde.'),
        })
      }
    } else {
      try {
        const data = {
          inspectionId: selectIspectionId,
          name: nameReport,
          type: reportId,
          filters: {
            subparkIds: selectSubparksIds,
            anomalyTypeIds: selectAnomalyTypeIds,
            minimumTemperature: Number(temperatureRange[0]),
            maximumTemperature: Number(temperatureRange[1]),
          },
        }

        const response = await postReports(data)

        if (response.success && response.data) {
          ToastNotification({
            id: 'success',
            type: 'success',
            message: t('Solicitação de Relatório gerado com sucesso!!'),
          })
          setSelectedSiteId('')
          setSelectIspectionId('')
          clearFilters()
          handleClose()
          onSuccess?.()
        } else {
          ToastNotification({
            id: 'error',
            type: 'error',
            message: t(response.message) ?? t('Erro ao solicitar relatório.'),
            errorMessage: response.errorDetails,
            errors: response.errors,
          })
        }
      } catch (error) {
        console.error(error)
        ToastNotification({
          id: 'error',
          type: 'error',
          message: t('Erro ao solicitar relatório.'),
        })
      }
    }

    setLoading(false)
  }

  useEffect(() => {
    loadSite()
  }, [])

  useEffect(() => {
    if (selectedSiteId != null && selectedSiteId != undefined && selectedSiteId != '') {
      loadInspections()
    }
  }, [selectedSiteId])

  useEffect(() => {
    if (selectIspectionId != null && selectIspectionId != undefined && selectIspectionId != '') {
      loadFilters()
    }
  }, [selectIspectionId])

  const clearFilters = () => {
    setSelectSubparksIds([])
    setSelectAnomalyTypeIds([])
    setTemperatureRange(['0', '100'])
  }

  const listboxProps = {
    style: {
      maxHeight: '200px',
      overflowY: 'auto',
    },
  }

  return (
    <Dialog open={open} onClose={handleClose} fullWidth>
      <DialogTitle>
        {reportId === 1
          ? t('Reports.ThermalReport')
          : reportId === 2
          ? t('Reports.ExecutiveReport')
          : reportId === 3
          ? t('Reports.CSVAnomalies')
          : t('Reports.CSVActivities')}
      </DialogTitle>
      <DialogContent>
        <FormControl fullWidth margin="normal">
          <Autocomplete
            sx={{ minWidth: 500 }}
            options={siteOptions}
            getOptionLabel={(option) => option.label}
            value={siteOptions.find((option) => option.value === selectedSiteId) || null}
            onChange={(event, newValue) => {
              setSelectedSiteId(newValue ? newValue.value : '')
              if (!newValue) clearFilters()
            }}
            renderInput={(params) => (
              <TextField
                sx={{ minWidth: 300 }}
                {...params}
                label={t('Selecione o Parque solar*')}
                variant="outlined"
              />
            )}
            renderOption={(props, option) => (
              <Box component="li" {...props} key={option.value}>
                {option.label}
              </Box>
            )}
            ListboxProps={{ style: listboxProps as React.CSSProperties }}
          />
        </FormControl>

        {selectedSiteId && selectedSiteId !== '' && reportId !== 4 && (
          <FormControl fullWidth margin="normal">
            <Autocomplete
              options={inspectionOptions}
              getOptionLabel={(option) => option.label}
              value={inspectionOptions.find((option) => option.value === selectIspectionId) || null}
              onChange={(event, newValue) => {
                setSelectIspectionId(newValue ? newValue.value : '')
                if (!newValue) clearFilters()
              }}
              renderInput={(params) => (
                <TextField {...params} label={t('Selecione a Inspeção*')} variant="outlined" />
              )}
              renderOption={(props, option) => (
                <Box component="li" {...props} key={option.value}>
                  {option.label}
                </Box>
              )}
              ListboxProps={{ style: listboxProps as React.CSSProperties }}
            />
          </FormControl>
        )}

        {selectIspectionId && selectedSiteId !== '' && reportId !== 4 && (
          <>
            <FormControl fullWidth margin="normal">
              <InputLabel shrink>{t('Selecione os Subparques')}</InputLabel>
              <Select
                multiple
                value={selectSubparksIds.map(String)}
                onChange={(e) => {
                  const value = e.target.value as unknown as string[]
                  const idsSubpark = value.map(Number)
                  setSelectSubparksIds(idsSubpark)
                }}
                input={<OutlinedInput notched label={t('Selecione os Subparques')} />}
                renderValue={(selected) => (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                    {selected.map((value) => (
                      <Chip
                        sx={{ gap: 1 }}
                        key={value}
                        label={reportsSubparks.find((option) => option.value === value)?.label}
                        onDelete={(event) => handleDelete(setSelectSubparksIds)(Number(value), event)}
                        onMouseDown={(e) => e.stopPropagation()}
                      />
                    ))}
                  </Box>
                )}
              >
                <MenuItem value="">
                  <em>{t('Nenhuma opção disponível')}</em>
                </MenuItem>
                {reportsSubparks.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl fullWidth margin="normal">
              <InputLabel shrink>{t('Selecione as Anomalias')}</InputLabel>
              <Select
                multiple
                value={selectAnomalyTypeIds.map(String)}
                onChange={(e) => {
                  const value = e.target.value as unknown as string[]
                  const idsAnomaly = value.map(Number)
                  setSelectAnomalyTypeIds(idsAnomaly)
                }}
                input={<OutlinedInput notched label={t('Selecione as Anomalias')} />}
                renderValue={(selected) => (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                    {selected.map((value) => {
                      const label =
                        anomalyType.find((option) => option.value === value)?.label ?? 'Unknown'
                      return (
                        <Chip
                          sx={{ gap: 1 }}
                          key={value}
                          label={t(label)}
                          onDelete={(event) =>
                            handleDelete(setSelectAnomalyTypeIds)(Number(value), event)
                          }
                          onMouseDown={(e) => e.stopPropagation()}
                        />
                      )
                    })}
                  </Box>
                )}
              >
                <MenuItem value="">
                  <em>{t('Nenhuma opção disponível')}</em>
                </MenuItem>
                {anomalyType.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {t(option.label)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </>
        )}

        {selectIspectionId && selectedSiteId !== '' && reportId !== 4 && (
          <>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12}>
                <Typography id="input-slider" gutterBottom>
                  {t('Intervalo de Temperatura')} (ΔT °C)
                </Typography>
                <Box style={{ width: '90%', margin: '0 auto' }}>
                  <Slider
                    value={temperatureRange.map((value) => parseFloat(value))}
                    min={0}
                    max={150}
                    onChange={handleSliderChange}
                    valueLabelDisplay="auto"
                    aria-labelledby="input-slider"
                    getAriaValueText={(value) => `${value}°C`}
                    marks={[
                      { value: 0, label: '0°C' },
                      { value: 150, label: '150°C' },
                    ]}
                  />
                </Box>
              </Grid>
              <Grid item xs>
                <TextField
                  sx={{ width: '100%' }}
                  size="small"
                  label={t('Temperatura Mínima °C') ?? 'Temperatura mínima'}
                  value={temperatureRange[0]}
                  onChange={handleInputChange(0)}
                  onBlur={() => handleBlur(0)}
                  inputProps={{
                    step: 1,
                    min: 0,
                    max: 150,
                    type: 'text',
                    'aria-labelledby': 'input-slider',
                  }}
                />
              </Grid>
              <Grid item xs>
                <TextField
                  sx={{ width: '100%' }}
                  size="small"
                  label={t('Temperatura Máxima °C') ?? 'Temperatura Máxima'}
                  value={temperatureRange[1]}
                  onChange={handleInputChange(1)}
                  onBlur={() => handleBlur(1)}
                  inputProps={{
                    step: 1,
                    min: 0,
                    max: 100,
                    type: 'text',
                    'aria-labelledby': 'input-slider',
                  }}
                />
              </Grid>
            </Grid>

            <FormControl fullWidth margin="normal">
              <S.ContainerSearchList>
                <TextField
                  label={t('Nome do Relatório') ?? 'Nome do Relatório'}
                  placeholder={t('Nome do Relatório') ?? 'Nome do Relatório'}
                  value={nameReport}
                  onChange={(e) => setNameReport(e.target.value)}
                  variant="outlined"
                  fullWidth
                  sx={{
                    width: '100%',
                  }}
                />
              </S.ContainerSearchList>
            </FormControl>
          </>
        )}

        <LoadingComponent loading={loading} text="Gerando Relatório..." />
      </DialogContent>
      <DialogActions sx={{ marginBottom: '16px' }}>
        <Button onClick={handleClose} variant="contained" color="primary">
          {t('Cancelar')}
        </Button>
        <Button
          onClick={createReports}
          color="primary"
          variant="contained"
          disabled={reportId === 4 ? !selectedSiteId : !selectIspectionId || !selectedSiteId}
        >
          {t('Gerar Relatório')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ReportModal
